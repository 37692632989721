<div class="transfer-stock-item-container">
  <form class="example-form" [formGroup]="form">

    <table mat-table #tblTransferStockItems [dataSource]="data" multiTemplateDataRows class="mat-elevation-z8 mat-table-transfer-stock-items">

      <ng-container matColumnDef="txtProductName">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemProductTH"> Product </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemProduct"> {{boardTallyRecordHistory.txtProductName}} </td>
      </ng-container>

      <ng-container matColumnDef="txtProfileName">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemProfileTH"> Profile </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemProfile"> {{boardTallyRecordHistory.txtProfileAbbrievation}} </td>
      </ng-container>

      <ng-container matColumnDef="intWidth">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemWidthTH"> Width </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemWidth"> {{boardTallyRecordHistory.intWidth}} </td>
      </ng-container>

      <ng-container matColumnDef="intThickness">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemThicknessTH"> Thick </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemThickness"> {{boardTallyRecordHistory.intThickness}} </td>
      </ng-container>

      <ng-container matColumnDef="txtSpeciesName">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemSpeciesTH"> Species </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemSpecies"> {{boardTallyRecordHistory.txtSpeciesName}} </td>
      </ng-container>

      <ng-container matColumnDef="txtGradeName">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemGradeTH"> Grade </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemGrade"> {{boardTallyRecordHistory.txtGradeName}} </td>
      </ng-container>

      <ng-container matColumnDef="fltQuantity">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityTH"> Qty </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemQuantity"> {{boardTallyRecordHistory.fltBoardTallyQuantity}} </td>
      </ng-container>

      <ng-container matColumnDef="txtQuantityUoM">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityUoMTH"> Qty UoM </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemQuantityUoM"> {{boardTallyRecordHistory.txtBoardTallyQuantityUoM}} </td>
      </ng-container>

      <ng-container matColumnDef="fltLength">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLengthTH"> Length </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemLength"> {{boardTallyRecordHistory.txtBoardTallyQuantityUoM.trim() == 'Each' ? boardTallyRecordHistory.intTotalLM : ''}} </td>
      </ng-container>

      <ng-container matColumnDef="fltDia">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemDiaTH"> Dia </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemDia"> {{boardTallyRecordHistory.fltBoardTallyDia == 0 ? '' : boardTallyRecordHistory.fltBoardTallyDia}} </td>
      </ng-container>

      <ng-container matColumnDef="txtLocation">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLocationTH"> Location </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemLocation toUpperCase"> {{boardTallyRecordHistory.txtContainerLocation}} </td>
      </ng-container>

      <ng-container matColumnDef="intIdentifier">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemIdentifierTH"> Identifier </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemIdentifier"> {{boardTallyRecordHistory.intIdentifier}} </td>
      </ng-container>

      <ng-container matColumnDef="dteDateCreated">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemCreatedTH"> Created </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemCreated"> {{boardTallyRecordHistory.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="txtStockKeepingUnit">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemSKUTH"> SKU </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory" class="stockitemTD stockItemSKU"> {{boardTallyRecordHistory.txtStockKeepingUnit}} </td>
      </ng-container>


      <ng-container matColumnDef="txtNewLocation">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemNewLocationTH"> New Location </th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory; let indexRow = dataIndex;" class="stockitemTD stockItemNewLocation">
          <mat-form-field class="example-full-width" appearance="fill" style="width:100px;">
            <mat-label>Location</mat-label>
            <input matInput #stocklocation mask="S-0-00" id="location{{indexRow}}" placeholder="i.e  P-0-16" value="" formControlName="txtStocktakeLocation" class="toUpperCase" (input)="boardTallyRecordHistory.txtContainerLocation = stocklocation.value" [attr.disabled]="stockItemExpanded == boardTallyRecordHistory || boardTallyRecordHistory.fltBoardTallyQuantity == 0?'':null" [readonly]="stockItemExpanded == boardTallyRecordHistory || boardTallyRecordHistory.fltBoardTallyQuantity == 0">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="chkFixedStockType">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemFixedStockTH" aria-label="row actions">Fixed Stock</th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory; let rowIdx = dataIndex;" class="stockitemTD stockItemFixedStock">
          <mat-checkbox class="example-margin" #chkfixedLocation id="chkfixedLocation{{rowIdx}}" (change)="boardTallyRecordHistory.blnFixedLocation = chkfixedLocation.checked" [checked]="stockItemExpanded == boardTallyRecordHistory?false:null" [disabled]="stockItemExpanded == boardTallyRecordHistory || boardTallyRecordHistory.fltBoardTallyQuantity == 0"></mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="btnReceiveStock">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH" aria-label="row actions"></th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory; let indexRow = dataIndex;" class="stockitemTD">
          <button mat-icon-button aria-label="expand row" type="button" mat-flat-button (change)="window.alert(stocklocation.value)" [disabled]="boardTallyRecordHistory.fltBoardTallyQuantity == 0" (click)="expandElement(boardTallyRecordHistory, indexRow);$event.stopPropagation()">
            <mat-icon *ngIf="stockItemExpanded !== boardTallyRecordHistory">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="stockItemExpanded === boardTallyRecordHistory">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="chkReceiveStock">
        <th mat-header-cell *matHeaderCellDef class="stockitemTH" aria-label="row actions"></th>
        <td mat-cell *matCellDef="let boardTallyRecordHistory; let rowIdx = dataIndex;" class="stockitemTD">
          <button class="transfer-whole-stock-button" type="button" mat-flat-button color="primary" (click)="transferWholeStock(boardTallyRecordHistory, rowIdx)" [disabled]="stockItemExpanded == boardTallyRecordHistory || boardTallyRecordHistory.fltBoardTallyQuantity == 0"><span class="transfer-whole-stock-button-text">Transfer Whole Stock</span></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedStockDetail">
        <td mat-cell *matCellDef="let boardTallyRecordHistory; let rowIdx = dataIndex;" class="stockitemTD" [attr.colspan]="displayedStockItems.length">
          <div class="example-element-detail" [@detailExpand]="stockItemExpanded == boardTallyRecordHistory ? 'expanded' : 'collapsed'">
            <app-stock-item [data]="stockItems" [stockItemFrom]="boardTallyRecordHistory" [geoStockLocation]="geoStockLocation" [stockItemFromIndex]="rowIdx" (transferToStockItemEvent)="transferToStockItem($event, boardTallyRecordHistory)" *ngIf="stockItemExpanded == boardTallyRecordHistory"></app-stock-item>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedStockItems"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedStockItems;"
          class="example-element-row"
          [class.example-expanded-row]="stockItemExpanded === boardTallyRecordHistory"></tr>

      <tr mat-row *matRowDef="let row; columns: ['expandedStockDetail'];" class="example-detail-row"></tr>

    </table>
    <div class="no-stock-items" *ngIf="data == null || data.length === 0"><h7>There are no stock items to transfer</h7></div>
  </form>
</div>
