<form class="example-form" [formGroup]="form">


  <div class="stock-transfer-receive-container">
    <h1>Stock Transfer Deliveries</h1>

    <mat-tab-group class="chh-mat-tab-group stock-transfer-receive-mat-tab-group" color="accent" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabDeliveryDockets>
      <mat-tab label="Stock Transfer Deiveries" class="tab-label chh-tab-labels">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Docket No</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" formControlName="txtSearchDeliveryDocketNo">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-column-2">
            <mat-form-field appearance="fill">
              <mat-label>Geo Location</mat-label>
              <mat-select formControlName="guGeoStockLocationId" multiple>
                <mat-option *ngFor="let geoStockLocation of geoStockLocations" [value]="geoStockLocation.rowguid">{{geoStockLocation.txtLocationName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button class="search-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets()">Search</button>
          </div>

          <div class="search-column-3">
              <mat-label><h5>Transferring To: <u>{{defaultGeoLocationName}}</u></h5></mat-label>
          </div>
        </div>
        <mat-divider></mat-divider>

        <table mat-table #tblDeliveryDocket [dataSource]="deliveryDockets" multiTemplateDataRows class="mat-elevation-z8" *ngIf="showTable">

          <ng-container matColumnDef="intDocketNo">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Docket No </th>
            <td mat-cell *matCellDef="let deliveryDocket" class="docketNo deliveryDocketTD alignRight"> {{deliveryDocket.intDocketNo}} </td>
          </ng-container>

          <ng-container matColumnDef="intLoadingDocketNo">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Loading Docket No </th>
            <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD loadingDocketNo alignRight"> {{deliveryDocket.intLoadingDocketNo}} </td>
          </ng-container>

          <ng-container matColumnDef="txtStatus">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Status </th>
            <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD status"> {{deliveryDocket.txtStatus}} </td>
          </ng-container>


          <ng-container matColumnDef="dteStatusDate">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Status Date </th>
            <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD statusDate alignRight"> {{deliveryDocket.dteStatusDate | date: 'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="txtHaulingCompanyName">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH"> Hauling Company </th>
            <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD haulingCompanyName"> {{deliveryDocket.txtHaulingCompanyName}} </td>
          </ng-container>

          <ng-container matColumnDef="dteCreatedDate">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight"> Date Created </th>
            <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD createdDate alignRight"> {{deliveryDocket.dteCreatedDate | date: 'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="btnSetAsDelivered">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH alignRight">  </th>
            <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD setAsComplete alignRight">
              <button class="search-option-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="setDeliveryDocketDelivered(deliveryDocket)" [disabled]="deliveryDocket.intStatus == 2">Set As Delivered</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="btnReceiveStock">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH" aria-label="row actions"></th>
            <td mat-cell *matCellDef="let deliveryDocket" class="deliveryDocketTD">
              <button mat-icon-button aria-label="expand row" type="button" mat-flat-button (click)="expandElement(deliveryDocket);$event.stopPropagation()">
                <mat-icon *ngIf="deliveryDocketExpanded !== deliveryDocket">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="deliveryDocketExpanded === deliveryDocket">keyboard_arrow_up</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <th mat-header-cell *matHeaderCellDef class="deliveryDocketTH" [attr.colspan]="displayedColumns.length"></th>
            <td mat-cell *matCellDef="let deliveryDocket; let rowIdx = dataIndex" class="deliveryDocketTD" [attr.colspan]="displayedColumns.length">
              <div class="example-element-detail" [@detailExpand]="deliveryDocketExpanded == deliveryDocket ? 'expanded' : 'collapsed'">
                <app-stock-transfer-stock-item #appstocktransfer [data]="stockItems" [geoStockLocation]="defaultGeoLocation" [stockContainerRackType]="stockContainerRackType" (transferToStockItemEvent)="transferToStockItem($event)" (transferWholeStockItemEvent)="transferWholeStockItem($event, rowIdx)" *ngIf="deliveryDocketExpanded === deliveryDocket"></app-stock-transfer-stock-item>
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [class.example-expanded-row]="deliveryDocketExpanded === deliveryDocket">
          </tr>

          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

        </table>


      </mat-tab>
      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container">
          <div class="search-option-row-1">
            <mat-form-field appearance="fill">
              <mat-label>Status</mat-label>
              <mat-select formControlName="intStatus" multiple>
                <mat-option *ngFor="let deliveryStatus of deliveryStatuses" [value]="deliveryStatus.intValue">{{deliveryStatus.txtValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-option-row-2">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Loading Docket No</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 55670" value="" type="text" formControlName="txtSearchLoadingDocketNo">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-option-row-3">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Status date from</mat-label>
              <input id="dteSearchStatusDateFrom" class="date-input receipt-date-search" #searchStatusDateFrom matInput [matDatepicker]="statuspickerfrom" formControlName="dteSearchStatusDateFrom">
              <mat-datepicker-toggle matSuffix [for]="statuspickerfrom"></mat-datepicker-toggle>
              <mat-datepicker #statuspickerfrom></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="search-option-row-4">
            <mat-form-field class="example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Status date to</mat-label>
              <input id="dteSearchStatusDateTo" class="date-input receipt-date-search" #searchReceiptDateTo matInput [matDatepicker]="statuspickerto" formControlName="dteSearchStatusDateTo">
              <mat-datepicker-toggle matSuffix [for]="statuspickerto"></mat-datepicker-toggle>
              <mat-datepicker #statuspickerto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-5">
            <mat-form-field class="example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Hauling Company Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Blanchards" value="" type="text" formControlName="txtSearchHaulingCompanyName">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets()">Search</button>
          </div>

          <mat-divider></mat-divider>

        </div>
      </mat-tab>
      <mat-tab label="Stock Items" class="tab-label">
        <div class="stock-items-container">
          <table mat-table #tblStockItems [dataSource]="stockItems" class="mat-elevation-z8">

            <ng-container matColumnDef="txtIdentifier">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Identifier </th>
              <td mat-cell *matCellDef="let StockItem" class="identifierCell stockitemTD"> {{StockItem.intIdentifier}} </td>
            </ng-container>

            <ng-container matColumnDef="txtProductName">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Product </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemProduct"> {{StockItem.txtProductName}} </td>
            </ng-container>

            <ng-container matColumnDef="txtProfileName">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Profile </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemProfile"> {{StockItem.txtProfileAbbrievation}} </td>
            </ng-container>

            <ng-container matColumnDef="intWidth">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemWidthTH"> W </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemWidth"> {{StockItem.intWidth}} </td>
            </ng-container>

            <ng-container matColumnDef="sizeSeparator">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"></th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemSizeSeparator">x</td>
            </ng-container>

            <ng-container matColumnDef="intThickness">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> T </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemThickness"> {{StockItem.intThickness}} </td>
            </ng-container>

            <ng-container matColumnDef="txtSpeciesName">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Species </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemSpecies"> {{StockItem.txtSpeciesName}} </td>
            </ng-container>

            <ng-container matColumnDef="txtGradeName">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Grade </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemGrade"> {{StockItem.txtGradeName}} </td>
            </ng-container>

            <ng-container matColumnDef="fltBoardTallyQuantity">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityTH"> Qty </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemQuantity"> {{StockItem.fltBoardTallyQuantity}} </td>
            </ng-container>

            <ng-container matColumnDef="txtBoardTallyQuantityUoM">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> UoM </th>
              <td mat-cell *matCellDef="let StockItem" [ngClass]="{'stockItemQuantityUOMCenter' : StockItem.txtBoardTallyQuantityUoM.trim()=='Each'}" class="stockitemTD stockItemQuantityUOM"><div>{{(StockItem.txtBoardTallyQuantityUoM.trim()=='Each' && StockItem.intTotalLM > 0) ? '/' : StockItem.txtBoardTallyQuantityUoM}} </div></td>
            </ng-container>

            <ng-container matColumnDef="intTotalLM">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLengthTH"> Length </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemLength"><div *ngIf="(StockItem.txtBoardTallyQuantityUoM.trim()=='Each' && StockItem.intTotalLM > 0)"> {{StockItem.intTotalLM}} </div></td>
            </ng-container>

            <ng-container matColumnDef="txtContainerLocation">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Location </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemLocation"> {{StockItem.txtContainerLocation}} </td>
            </ng-container>

            <ng-container matColumnDef="txtStockType">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> Type </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemLocation">{{StockItem.txtStockContainerTypeName}}</td>
            </ng-container>

            <ng-container matColumnDef="txtLocation">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLocationTH"> Location </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemLocation"><input class="stock-location" type="text" name="txtLocation" value="" id="txtLocation{{rowIdx}}" /></td>
            </ng-container>

            <ng-container matColumnDef="txtTransferToPack">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemTransferToPackTH"> Transfer To Pack </th>
              <td mat-cell *matCellDef="let StockItem" class="stockitemTD stockItemTransferToPack">
                <mat-form-field appearance="fill">
                  <mat-label>Geo Location</mat-label>
                  <mat-select id="guStockItemId{{rowIdx}}">
                    <mat-option *ngFor="let transferToStockItem of transferToStockItems" [value]="transferToStockItem.rowguid">{{transferToStockItem.intIdentifier}}  {{transferToStockItem.txtProductName}}  {{transferToStockItem.txtProfileName}}  {{transferToStockItem.txtSpeciesName}}  {{transferToStockItem.txtGradeName}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="btnTransferPack">
              <th mat-header-cell *matHeaderCellDef class="stockitemTH"> </th>
              <td mat-cell *matCellDef="let StockItem; let rowIdx = index;" class="stockitemTD"><button type="button" class="stocktakeButton" mat-flat-button color="primary" id="btnTransferPack{{rowIdx}}" (click)="transferPack(StockItem, rowIdx)">Transfer Pack</button></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedStockItems"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedStockItems;"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>


  </div>
</form>

<div class="spinner-container">
  <mat-card [@displaySpinner]="transitionProducts ? 'open' : 'closed'" class="products-spinner-card" *ngIf="showProductsSpinner">
    <mat-card-content class="spinner-content">
      <mat-progress-spinner class="example-margin products-spinner" color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>

