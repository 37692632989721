import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { dtoStockItem } from '../business-objects/dto-stock-item.bo';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoOrderItem } from '../business-objects/dto-order-item.bo';

@Injectable()
export class dtoStockItemService
{

	public _baseUrl: string;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoStockItem(dtoStockItemId: string) {
		return this.http.get<dtoStockItem>(this._baseUrl + 'api/dtoStockItem/' + dtoStockItemId);
    }

    getdtoStockItemParamArray(aParam: SQLParamArray[][]) {
      return this.http.post<dtoStockItem[]>(this._baseUrl + 'api/dtoStockItem/GetdtoStockItemsParamArray/', aParam);
    }

	getAlldtoStockItems() {
		return this.http.get<dtoStockItem[]>(this._baseUrl + 'api/dtoStockItem/GetAlldtoStockItems/');
	}

	//getAlldtodtoStockItems() {
	//	return this.http.get<dtodtoStockItem[]>(this._baseUrl + 'api/dtoStockItem/GetAlldtoStockItems/');
	//}

	createdtoStockItem(dtoStockItem: dtoStockItem) {
		return this.http.post<dtoStockItem>(this._baseUrl + 'api/dtoStockItem/CreatedtoStockItem/', dtoStockItem);
	}

	updatedtoStockItem(dtoStockItem: dtoStockItem) {
		return this.http.post<dtoStockItem>(this._baseUrl + 'api/dtoStockItem/UpdatedtoStockItem/', dtoStockItem);
  }

  async getdtoStockItemPromise(stockItemId: string) {
    return new Promise<dtoStockItem>(resolve => {
      this.getdtoStockItem(stockItemId).subscribe(stockItem => {
        resolve(stockItem);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }


  async getdtoStockItemParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoStockItem[]>(resolve => {
      this.getdtoStockItemParamArray(aParam).subscribe(dtoStockItems => {
        resolve(dtoStockItems);
      }, err => {
        this.alertService.openSnackBarError("Error getting dtoStockItem records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async updatedtoStockItemPromise(dtoStockItem: dtoStockItem) {
    return new Promise<dtoStockItem>(resolve => {
      this.updatedtoStockItem(dtoStockItem).subscribe(dtoStockItem => {
        resolve(dtoStockItem);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating dtoStockItem record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async createdtoStockItemPromise(dtoStockItem: dtoStockItem) {
    return new Promise<dtoStockItem>(resolve => {
      this.createdtoStockItem(dtoStockItem).subscribe(dtoStockItem => {
        resolve(dtoStockItem);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating dtoStockItem record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  createStockItemFromOrderItem(orderItem: dtoOrderItem, stockItemLength: number, updateQuantity: number, moulderId: string, machineRunId: string, itemNo: number) {


    let stockItem: dtoStockItem = new dtoStockItem();
    stockItem.rowguid = window.crypto['randomUUID']();  // Javascript GUID generator.
    stockItem.guProfileId = orderItem.guProductTypeId;
    stockItem.guProductProfileId = orderItem.guProductProfileId;
    stockItem.txtProductName = orderItem.txtProductName;
    stockItem.txtProductShortName = orderItem.txtProductShortName;
    stockItem.txtProfileName = orderItem.txtProfileName;
    stockItem.txtProfileAbbrievation = orderItem.txtProfileAbbrievation;
    stockItem.intWidth = orderItem.fltWidth;
    stockItem.intThickness = orderItem.fltThickness;
    stockItem.intTotalLM = orderItem.fltLength;
    if (stockItemLength > orderItem.fltLength) {
      stockItem.intTotalLM = stockItemLength;
    }
    stockItem.guBoardGradeId = orderItem.guGradeId;
    stockItem.txtGradeName = orderItem.txtGradeName;
    //stockItem.txtGradeAbbreviation = orderItem.txtGradeAbbreviation  // order item abbreviation does not exist, should create it.
    stockItem.guSpeciesId = orderItem.guSpeciesId;
    stockItem.txtSpeciesName = orderItem.txtSpeciesName;
    //stockItem.txtSpeciesCode = orderItem.txtSpeciesCode;   // order item species code does not exist, should create it.
    stockItem.fltQuantity = orderItem.fltQtyBal;
    if (updateQuantity != null && updateQuantity > 0) {
      stockItem.fltQuantity = updateQuantity;
    }
    stockItem.intQuantityUoM = orderItem.intQuantityUoM;
    stockItem.txtQuantityUoM = orderItem.txtQuantityUoM;
    stockItem.guBoardOrderId = orderItem.guOrderId;
    stockItem.guOrderItemId = orderItem.rowguid;
    stockItem.guCustomerId = orderItem.guCustomerId;
    stockItem.guProductCodeId = orderItem.guProductCodeGuidId;
    stockItem.blnFinalDelivery = true;

    console.log("guProductCodeGuidId ", orderItem.guProductCodeGuidId);

    if (moulderId != null && moulderId != "") {
      stockItem.guMoulderId = moulderId;
    }

    if (machineRunId != null && machineRunId != "") {
      stockItem.guMachineRunId = machineRunId;
    }

    stockItem.intItemNo = itemNo;

    return stockItem;
  }

}
