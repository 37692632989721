import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoTaxInvoiceMonthlyTotals } from '../business-objects/dto-tax-invoice-monthly-totals.bo';

@Injectable()
export class dtoTaxInvoiceMonthlyTotalsService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotalsId: string) {
		return this.http.get<dtoTaxInvoiceMonthlyTotals>(this._baseUrl + 'api/dtoTaxInvoiceMonthlyTotals/' + dtoTaxInvoiceMonthlyTotalsId);
	}

	getdtoTaxInvoiceMonthlyTotalsParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoTaxInvoiceMonthlyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceMonthlyTotals/GetdtoTaxInvoiceMonthlyTotalsParamArray/', aParam);
	}

	getAlldtoTaxInvoiceMonthlyTotalss() {
		return this.http.get<dtoTaxInvoiceMonthlyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceMonthlyTotals/GetAlldtoTaxInvoiceMonthlyTotals/');
	}

	//getAlldtodtoTaxInvoiceMonthlyTotalss() {
	//	return this.http.get<dtodtoTaxInvoiceMonthlyTotals[]>(this._baseUrl + 'api/dtoTaxInvoiceMonthlyTotals/GetAlldtoTaxInvoiceMonthlyTotals/');
	//}

	createdtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotals: dtoTaxInvoiceMonthlyTotals) {
		return this.http.post<dtoTaxInvoiceMonthlyTotals>(this._baseUrl + 'api/dtoTaxInvoiceMonthlyTotals/CreatedtoTaxInvoiceMonthlyTotals/', dtoTaxInvoiceMonthlyTotals);
	}

	updatedtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotals: dtoTaxInvoiceMonthlyTotals) {
		return this.http.put<dtoTaxInvoiceMonthlyTotals>(this._baseUrl + 'api/dtoTaxInvoiceMonthlyTotals/UpdatedtoTaxInvoiceMonthlyTotals/', dtoTaxInvoiceMonthlyTotals);
	}

	deletedtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotalsId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoTaxInvoiceMonthlyTotals/DeletedtoTaxInvoiceMonthlyTotals/' + dtoTaxInvoiceMonthlyTotalsId);
	}

	async getdtoTaxInvoiceMonthlyTotalsPromise(dtoTaxInvoiceMonthlyTotalsId: string) {
		return new Promise<dtoTaxInvoiceMonthlyTotals>(resolve => {
			this.getdtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotalsId).subscribe(dtoTaxInvoiceMonthlyTotals => {
				resolve(dtoTaxInvoiceMonthlyTotals);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceMonthlyTotals records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoTaxInvoiceMonthlyTotalsParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoTaxInvoiceMonthlyTotals[]>(resolve => {
			this.getdtoTaxInvoiceMonthlyTotalsParamArray(aParam).subscribe(dtoTaxInvoiceMonthlyTotals => {
				resolve(dtoTaxInvoiceMonthlyTotals);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoTaxInvoiceMonthlyTotals records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoTaxInvoiceMonthlyTotalsPromise(dtoTaxInvoiceMonthlyTotals: dtoTaxInvoiceMonthlyTotals) {
		return new Promise<dtoTaxInvoiceMonthlyTotals>(resolve => {
			this.updatedtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotals).subscribe(dtoTaxInvoiceMonthlyTotals => {
				resolve(dtoTaxInvoiceMonthlyTotals);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoTaxInvoiceMonthlyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoTaxInvoiceMonthlyTotalsPromise(dtoTaxInvoiceMonthlyTotals: dtoTaxInvoiceMonthlyTotals) {
		return new Promise<dtoTaxInvoiceMonthlyTotals>(resolve => {
			this.createdtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotals).subscribe(dtoTaxInvoiceMonthlyTotals => {
				resolve(dtoTaxInvoiceMonthlyTotals);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoTaxInvoiceMonthlyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoTaxInvoiceMonthlyTotalsPromise(dtoTaxInvoiceMonthlyTotalsId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoTaxInvoiceMonthlyTotals(dtoTaxInvoiceMonthlyTotalsId).subscribe(dtoTaxInvoiceMonthlyTotals => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoTaxInvoiceMonthlyTotals record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}