export interface EmailMessage {
  constructor (from: string, to: string, cc: string, bc: string, subject: string, body: string, attachments: string[], isHTML: boolean)
  From: string;
  To: string;
  Subject: string;
  Body: string;
  Attachments: string[];
}

export class EmailMessage {
    constructor (from: string, to: string, cc: string, bc: string, subject: string, body: string, attachments: string[], isHTML: boolean) {
    this.From = from;
    this.To = to;
    this.CC = cc;
    this.BC = bc;
    this.Subject = subject;
    this.Body = body;
    this.Attachments = attachments;
    this.IsHTML = isHTML;
  }

  From: string;
  To: string;
  CC: string;
  BC: string
  Subject: string;
  Body: string;
  Attachments: string[];
  IsHTML: boolean;
}
