
export interface dtoKeyValue {
  intValue: number;
  decValue: number;
  dteValue: Date;
  txtValue: string;
}

export class dtoKeyValue {
  intValue: number;
  decValue: number;
  dteValue: Date;
  txtValue: string;
  txtValue2: string;
}



export interface dtoIntKeyValue {
  constructor(value: number, text: string);
  intValue: number;
  txtValue: string;
}

export class dtoIntKeyValue {
  constructor(value: number, text: string) {
    this.intValue = value;
    this.txtValue = text;
  }
  intValue: number;
  txtValue: string;
}



export interface dtoStrKeyValue {
  intValue: string;
  txtValue: string;
}

export class dtoStrKeyValue {
  intValue: string;
  txtValue: string;
}

export interface dtoKeyValueBarcode {
  intValueBarcode: ArrayBuffer;
  txtValueBarcode: ArrayBuffer;
  txtValueBarcodeLarge: ArrayBuffer;
}

export class dtoKeyValueBarcode {
  intValueBarcode: ArrayBuffer;
  txtValueBarcode: ArrayBuffer;
  txtValueBarcodeLarge: ArrayBuffer;
}
