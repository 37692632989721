import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { dtoTaxInvoiceDailyTotalsService } from '../../../_shared/business-objects/dto-tax-invoice-daily-totals.service';
import { NavigationService } from '../../../_shared/services/navigation.service';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { dtoTaxInvoiceDailyTotals } from '../../../_shared/business-objects/dto-tax-invoice-daily-totals.bo';
import { ChhChartComponent } from '../chh-chart/chh-chart.component';
import { SaleLocation } from '../../../_shared/business-objects/sale-location.bo';
import { SaleLocationService } from '../../../_shared/services/sale-location.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatSlider } from '@angular/material/slider';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../../_shared/services/email.service';
import { EmailMessage } from '../../../_shared/app-objects/email-message';
import { AuthService } from '../../../account/auth.service';
import { dtoTaxInvoiceWeeklyTotals } from '../../../_shared/business-objects/dto-tax-invoice-weekly-totals.bo';
import { dtoTaxInvoiceMonthlyTotals } from '../../../_shared/business-objects/dto-tax-invoice-monthly-totals.bo';
import { dtoTaxInvoiceWeeklyTotalsService } from '../../../_shared/services/dto-tax-invoice-weekly-totals.service';
import { dtoTaxInvoiceMonthlyTotalsService } from '../../../_shared/services/dto-tax-invoice-monthly-totals.service';

@Component({
  selector: 'app-pos-sales',
  templateUrl: './pos-sales.component.html',
  styleUrl: './pos-sales.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PosSalesComponent implements OnInit {

  @ViewChild("posSalesChart") posSalesChart: ChhChartComponent;
  @ViewChild("chartWidthSlider") chartWidthSlider: MatSlider;

  

  taxInvoiceDailyTotals: dtoTaxInvoiceDailyTotals[];
  taxInvoiceWeeklyTotals: dtoTaxInvoiceWeeklyTotals[];
  taxInvoiceMonthlyTotals: dtoTaxInvoiceMonthlyTotals[];
  chartTitle: string;
  chartDataSet: ChartDataset[];
  chartLabel: string[];
  chartType: ChartType;
  chartLegend: boolean;
  chartOptions: ChartOptions;

  reportType: string;

  saleLocations: SaleLocation[];

  form: FormGroup;

  dateRange: { value: number, text: string }[];
  fromDate: string;
  toDate: string;
  saleLocationId: string;

  chartWidth: number;
  screenMode: boolean = true;
  chartBase64Array: string[] = [];


  constructor(private dtoTaxInvoiceDailyTotalsService: dtoTaxInvoiceDailyTotalsService, private dtoTaxInvoiceWeeklyTotalsService: dtoTaxInvoiceWeeklyTotalsService
    , private dtoTaxInvoiceMonthlyTotalsService: dtoTaxInvoiceMonthlyTotalsService
    , private navigationService: NavigationService, private saleLocationService: SaleLocationService, private fb: FormBuilder, private route: ActivatedRoute
    , private emailService: EmailService, private authService: AuthService) {

    this.form = this.fb.group({
      dteDateFrom: [''],
      dteDateTo: [''],
      guSaleLocationId: [''],
      txtDateSelection: [4]
    });

  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(async params => {

      if (params != null) {
        let displayMode: string = params["displayMode"];
        let email: string = params["email"];
        let fromDate: string = params["fromDate"];
        let toDate: string = params["toDate"];
        let saleLocationId: string = params["saleLocationId"];
        let reportType: string = params["reporttype"];


        console.log("Object.prototype.toString.call(fromDate): ", Object.prototype.toString.call(new Date(fromDate)));
        if (fromDate != null && fromDate != "" && Object.prototype.toString.call(new Date(fromDate)) === '[object Date]') {
          this.fromDate = fromDate;
        console.log("fromDate: ", fromDate);
          this.form.controls['dteDateFrom'].setValue(new Date(fromDate));
        }

        if (toDate != null && toDate != "" && Object.prototype.toString.call(new Date(toDate)) === '[object Date]') {
          this.toDate = toDate;
          this.form.controls['dteDateTo'].setValue(new Date(toDate));
          console.log("toDate: ", toDate);
        }

        if ((displayMode != null && displayMode.toLowerCase() == "noscreen")) {
          this.screenMode = false;

          if (params["txtToken"] == null || params["txtToken"] == "") {
            // No token no go.
            return;
          }


          if ((reportType != null && reportType != "")) {
            this.reportType = reportType;
          }

          console.log("screenMode: ", this.screenMode);
          //console.log("hidden: ", this.navBar.nativeElement.hidden);

          this.runTasks();

          setTimeout(async () => {


            //let chartBase64Div: HTMLDivElement = document.getElementById("chartBase64") as HTMLDivElement;
            //chartBase64Div.innerHTML = await this.getChartBase64();

            //if ((email != null && email.toLowerCase() == "yes")) {
            //  await this.emailChart();
            //}

          }, 500);


        }


      }

    });


    this.chartWidth = 1600;


    this.dateRange = [];
    this.dateRange.push({ value: 1, text: "1 Week" });
    this.dateRange.push({ value: 2, text: "2 Weeks" });
    this.dateRange.push({ value: 3, text: "3 Weeks" });
    this.dateRange.push({ value: 4, text: "4 Weeks" });
    this.dateRange.push({ value: 3, text: "3 Months" });
    this.dateRange.push({ value: 6, text: "6 Months" });
    this.dateRange.push({ value: 12, text: "12 Months" });


    //this.weeklyPOSSales(2024, '22222222-2222-2222-2222-222222222222');

    //this.reportType = "weeklysales";
    //this.runTasks();

  }

  ngAfterViewInit() {

    console.log("ngAfterViewInit");
    if (this.screenMode = true) {
      this.loadData();
    }

  }

  async testEmail() {
    let emailBody: string = "Test Email";

    let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Test Email", emailBody, null, true);
    this.emailService.sendEmail(emailMessage);

  }


  async office365Login() {
    let isLoggedIn: boolean = await this.authService.isOffice365LoggedIn();

    console.log("isLoggedIn: ", isLoggedIn);

    let emailBody: string = "Test Office 365 Login\n\nIsLoggedIn  - " + isLoggedIn;

    let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Test Office 365 Login", emailBody, null, true);
    this.emailService.sendEmail(emailMessage);

  }


  async loadData() {



    //setTimeout(async () => {
    //}, 1500);

    //if (String(this.fromDate) == "" && String(this.toDate) == "") {
    //  this.setSalesDate(4);
    //}

    //this.chartTitle = "Lawson Cresent POS Sales";
    //let saleLocationId: string = await this.navigationService.getLocationGuid("Coffs");
    //this.form.controls['guSaleLocationId'].setValue(saleLocationId);
    //this.getTaxInvoiceDailyTotals(this.fromDate, this.toDate, saleLocationId);

  }

  async runTasks() {
    await this.getSaleLocations();
    await this.runPOSGraphs();
    //await this.emailPOSGraphs();
  }

  async runPOSGraphs() {

    return new Promise<boolean>(async resolve => {
      //console.log("this.posSalesChart: ", this.posSalesChart);

      if (this.saleLocations != null) {

        for (let i = 0; i <= this.saleLocations.length - 1; i++) {

          this.taxInvoiceDailyTotals = null; // have to do this to refresh graph.

          if (this.reportType == "dailysales") {
            await this.loadChart(this.saleLocations[i].txtLocationName + " POS Daily Sales", this.fromDate, this.toDate, this.saleLocations[i].rowguid);
          }

          if (this.reportType == "weeklysales") {
            await this.weeklyPOSSales(this.saleLocations[i].txtLocationName + " POS Weekly Sales", new Date().getFullYear(), this.saleLocations[i].rowguid);
          }

          setTimeout(async () => {
          }, 500);
        }

        resolve(true);

        //console.log("chartsBase64: ", this.chartBase64Array);
      }

      console.log("runPOSGraphs");
    });

  }

  async loadChart(chartTitle: string, fromDate: string, toDate: string, saleLocationId: string) {
    return new Promise<boolean>(async resolve => {
      this.chartTitle = chartTitle;

      console.log("chartTitle", this.chartTitle);

      await this.getTaxInvoiceDailyTotals(fromDate, toDate, saleLocationId);
      await this.timeWait();
      let base64: string = await this.getChartBase64();
      this.chartBase64Array.push(base64);
      resolve(true);
    });

  }


  async timeWait() {
    return new Promise<boolean>(resolve => {
      setTimeout(() => {
        console.log("in timeWait")
        resolve(true);
      }, 500);
    });
  }


  async emailPOSGraphs() {

    if (this.chartBase64Array.length > 0) {

      let emailBody: string = "<h1>POS Graphs</h1></br><br><br><br>";

      //emailBody += "<p><img src=\"" + await this.getChartBase64() + "\"></p><br><br>";

      for (let i = 0; i <= this.chartBase64Array.length - 1; i++) {
        emailBody += "<p><img src=" + this.chartBase64Array[i].toString() + "></p><br><br>";
      }

      let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "POS Graphs", emailBody, null, true);
      //console.log(emailMessage.Body);
      //console.log(this.posSalesChart.chartBase64());

      this.emailService.sendEmail(emailMessage);

      let chartBase64Div: HTMLDivElement = document.getElementById("chartBase64") as HTMLDivElement;
      chartBase64Div.innerHTML = this.chartBase64Array[ this.chartBase64Array.length - 1];

    }

  }

  async getSaleLocations() {
    return new Promise<boolean>(resolve => {

      this.saleLocationService.getAllSaleLocations().subscribe((saleLocations) => {
        this.saleLocations = saleLocations;
        console.log("sale locations");
        resolve(true);
      })
    });

  }

  async getTaxInvoiceDailyTotals(dateFrom: string, dateTo: string, saleLocationId: string) {

    this.taxInvoiceDailyTotals = await this.dtoTaxInvoiceDailyTotalsService.getdtoTaxInvoiceDailyTotalsByDates(dateFrom, dateTo, saleLocationId);

    //console.log("taxInvoiceDailyTotals", this.taxInvoiceDailyTotals);

    this.chartOptions = {
      responsive: true,
      layout: {
        padding: 50
      },
      plugins: {
        legend: {
          display: this.chartLegend
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 1,
          font: {
            size: 15,
            weight: 'bold'
          },
          formatter: function (value, context) {
            return '$' + value;
          }
        },
        title: {
          display: true,
          text: this.chartTitle,
          font: {
            size: 40,
            weight: 'bold'
          }
        }
      },
      scales: {
        y: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return '$' + value;
            },
            font: {
              size: 15
            }
          }
        }
      }
    }

    this.chartDataSet = [{ data: this.taxInvoiceDailyTotals.map((a) => { return Math.round(a.fltTotalPriceExGST) }), label: 'POS Daily Totals', tension: 0.2 }];

    this.chartLabel = this.taxInvoiceDailyTotals.map((a) => { return new Date(a.dteDateCreated).toLocaleDateString('en-AU', { weekday: 'long' }) + '\n' + new Date(a.dteDateCreated).toLocaleDateString() });
    this.chartType = "line";
    this.chartLegend = true;

    //console.log("chartDataSet", this.chartDataSet);

    //this.posSalesChart.loadChart();


  }

  async weeklyPOSSales(chartTitle: string, year: number, saleLocationId: string) {
    return new Promise<boolean>(async resolve => {
      this.taxInvoiceWeeklyTotals = await this.dtoTaxInvoiceWeeklyTotalsService.getdtoTaxInvoiceWeeklyTotalsByDates(year, saleLocationId);
      this.chartTitle = chartTitle;
      let dataSet: ChartDataset[] = [{ data: this.taxInvoiceWeeklyTotals.map((a) => { return Math.round(a.fltTotalPriceExGST) }), label: 'POS Weekly Totals', tension: 0.2 }];
      let chartLabel: string[] = this.taxInvoiceWeeklyTotals.map((a) => { return new Date(a.dteWeekEnd).toLocaleDateString('en-AU', { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short'}) });

      console.log(this.taxInvoiceWeeklyTotals);

      await this.setChartData(dataSet, chartLabel);
      resolve(true);

    });
  }


  async setChartData(chartDataSet: ChartDataset[], chartLabel: string[]) {

    this.chartOptions = {
      responsive: true,
      layout: {
        padding: 50
      },
      plugins: {
        legend: {
          display: this.chartLegend
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 1,
          font: {
            size: 15,
            weight: 'bold'
          },
          formatter: function (value, context) {
            return '$' + value;
          }
        },
        title: {
          display: true,
          text: this.chartTitle,
          font: {
            size: 40,
            weight: 'bold'
          }
        }
      },
      scales: {
        y: {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return '$' + value;
            },
            font: {
              size: 15
            }
          }
        }
      }
    }

    this.chartDataSet = chartDataSet;
    this.chartLabel = chartLabel;
    this.chartType = "line";
    this.chartLegend = true;

    //console.log("chartDataSet", this.chartDataSet);

    //this.posSalesChart.loadChart();


  }

  updateChart() {

    console.log("update chart");

    this.taxInvoiceDailyTotals = null;

    let dateFrom: string = this.form.controls['dteDateFrom'].value;
    let dateTo: string = this.form.controls['dteDateTo'].value;
    let saleLocationId: string = this.form.controls['guSaleLocationId'].value;

    console.log("dateFrom", dateFrom);
    console.log("dateTo", dateTo);
    console.log("saleLocationId", saleLocationId);

    this.getTaxInvoiceDailyTotals(dateFrom, dateTo, saleLocationId);


  }

  refreshChart() {

    let tempTotals = this.taxInvoiceDailyTotals;

    this.taxInvoiceDailyTotals = null;

    setTimeout(() => { this.taxInvoiceDailyTotals = tempTotals }, 500);


  }


  async setSalesDate(frequencyIndex: number) {

    console.log("freq: ", this.dateRange[frequencyIndex]);


    var someDate = new Date();
    let numberOfDaysToAdd: number;

    if (this.dateRange[frequencyIndex].text.toLowerCase().indexOf("weeks") > -1) {
      numberOfDaysToAdd = this.dateRange[frequencyIndex].value * 7;
      var result = someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
      this.form.controls['dteDateFrom'].setValue(new Date(result));
      this.form.controls['dteDateTo'].setValue(new Date(Date.now()));

    }

    if (this.dateRange[frequencyIndex].text.toLowerCase().indexOf("month") > -1) {
      numberOfDaysToAdd = (this.dateRange[frequencyIndex].value * 4) * 7;
      var result = someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
      this.form.controls['dteDateFrom'].setValue(new Date(result));
      this.form.controls['dteDateTo'].setValue(new Date(Date.now()));
    }

    //console.log(new Date(result))

    this.updateChart();


  }

  formatLabel(value: number): string {
    //console.log("value: ", value);

    if (value >= 1000) {
    }

    return Math.round(value) + 'px';

    return `${value}`;
  }

  setChartWidth(e: Event) {

    console.log("e", e.target);

    let slider: HTMLInputElement = e.target as HTMLInputElement;

    console.log("input", slider.value);

    this.chartWidth = Number(slider.value);

    this.refreshChart();

  }


  setChartTitle() {

    //let taxInvoiceDailyTotals = this.taxInvoiceDailyTotals;
    //this.taxInvoiceDailyTotals = null;
     let saleLocationId: string = this.form.controls['guSaleLocationId'].value;
    for (let i = 0; i <= this.saleLocations.length - 1; i++) {
      if (saleLocationId == this.saleLocations[i].rowguid) {
        this.chartTitle = this.saleLocations[i].txtLocationName + " POS Sales";

        console.log("freq: ", this.form.controls['guSaleLocationId'].value);
        break;
      }
    }

    this.updateChart();

    //setTimeout(() => { this.taxInvoiceDailyTotals = taxInvoiceDailyTotals }, 500);


  }

  downloadChart(){
    let chartBase64 = this.posSalesChart.downloadChart();

    console.log("chartBase64", chartBase64);

  }

  getChartBase64() {
    return this.posSalesChart.chartBase64();
  }

  async emailChart() {

    let emailBody: string = "Test Graph Email\n\nn";
    emailBody += "<p><img src=\"" + await this.getChartBase64() + "\"></p>";

    let emailMessage: EmailMessage = new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Test Graph Email", emailBody, null, true);
    //console.log(JSON.stringify(emailMessage));

    this.emailService.sendEmail(emailMessage);

  }

}
