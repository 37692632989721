<form class="example-form delivery-docket-container-form" [formGroup]="form">

  <div class="delivery-docket-container" id="deliveryDocketContainer">
    <div class="delivery-docket-heading">
      <h1>Delivery Dockets</h1>
    </div>

    <mat-tab-group color="accent" class="chh-mat-tab-group delivery-docket-mat-tab-group" backgroundColor="primary" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)" #tabDeliveryDockets>
      <mat-tab label="Delivery Dockets" class="tab-label">
        <div class="search-fields-container">
          <div class="search-column-1">
            <mat-form-field class="example-full-width" style="width: 200px !important" appearance="fill">
              <mat-label>Docket No</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 45680" value="" type="text" id="txtSearchDeliveryDocketNo" formControlName="txtSearchDeliveryDocketNo" (keydown.enter)="searchDeliveryDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-buttons">
            <div class="search-button-column">
              <button class="search-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets('')">Search</button>
            </div>

            <div class="search-ready-for-dispatch-button-column">
              <button class="search-delivery-docket-button chh-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets('readyfordispatch')">Show Ready For Dispatch</button>
            </div>

            <div class="search-scheduled-loads-button-column">
              <button class="next-delivery-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets('scheduledloads')">Show Scheduled, Loaded and Dispatched Loads</button>
            </div>

            <div class="search-next-delivery-button-column">
              <button class="next-delivery-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets('nextdelivery')">Show Next Deliveries</button>
            </div>

            <div class="search-to-be-invoiced-button-column">
              <button class="invoice-to-be-emailed-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets('invoicetobeemailed')" *ngIf="officeAdmin">Show Invoices To Be Emailed</button>
            </div>

            <div class="search-to-be-uploaded-button-column">
              <button class="invoice-to-be-uploaded-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets('invoicetobeuploaded')" *ngIf="officeAdmin">Show Invoices To Be Uploaded</button>
            </div>

          </div>
        </div>

        <mat-divider></mat-divider>
        <div class="spinner-container" *ngIf="showSpinner">
          <div [@displaySpinner]="showSpinner ? 'open' : 'closed'" class="spinner-content" *ngIf="showSpinner">
            <mat-progress-spinner class="example-margin products-spinner"  color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
          </div>
        </div>

        <div class="delivery-docket-list-container">
          <app-delivery-docket-list #appDeliveryDocketList [datasource]="deliveryDockets" [openDeliveryDocket]="openDeliveryDocket" [columnBarPage]="'Delivery Dockets'" (editDeliveryDocketEvent)="editDeliveryDocket($event)" (addDeliveryDocketItemsEvent)="addDeliveryDocketItems($event)" *ngIf="deliveryDockets != null"></app-delivery-docket-list>
        </div>

      </mat-tab>

      <mat-tab label="Search Options" class="tab-label">
        <div class="search-options-fields-container">
          <div class="search-option-row-1">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Customer</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Essential Energy" value="" type="text" id="txtSearchCustomer" formControlName="txtSearchCustomer" (keydown.enter)="searchDeliveryDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
            <div class="search-options-button-container"><button class="search-options-delivery-docket-button" type="button" mat-flat-button color="primary" id="btnSearchOptions" (click)="searchDeliveryDockets('')">Search</button></div>
          </div>

          <div class="search-option-row-1">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Location</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Dubbo" value="" type="text" id="txtSearchLocation" formControlName="txtSearchLocation" (keydown.enter)="searchDeliveryDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>


          <div class="search-option-row-1">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Order Number</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. EE410034556" value="" type="text" formControlName="txtSearchOrderNumber" (keydown.enter)="searchDeliveryDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-1">
            <div class="search-product-type-container">
              <app-product-type-select [productTypes]="productTypes" (selectedProductTypesEvent)="selectedProductTypes($event)"></app-product-type-select>
            </div>
          </div>

          <div class="search-option-row-1">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Final Number</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 10870" value="" type="text" formControlName="txtSearchFinalNumber" (keydown.enter)="searchDeliveryDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill" class="chh-form-field">
              <mat-label>Status</mat-label>
              <mat-select formControlName="intStatus" multiple>
                <mat-option *ngFor="let deliveryStatus of deliveryStatuses" [value]="deliveryStatus.intValue">{{deliveryStatus.txtValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-option-row-1">
            <mat-form-field appearance="fill" class="chh-form-field">
              <mat-label>Delivery Type</mat-label>
              <mat-select formControlName="intDeliveryType" multiple>
                <mat-option *ngFor="let deliveryType of deliveryTypes" [value]="deliveryType.intValue">{{deliveryType.txtValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="search-option-row-2">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important" appearance="fill">
              <mat-label>Loading Docket No</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. 55670" value="" type="text" id="txtSearchLoadingDocketNo" formControlName="txtSearchLoadingDocketNo" (keydown.enter)="searchDeliveryDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple numbers by a comma i.e. 55670,28631..." class="help-icon">help_outline</mat-icon>
          </div>
          <div class="search-option-row-3">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Status date from</mat-label>
              <input id="dteSearchStatusDateFrom" class="date-input receipt-date-search" #searchStatusDateFrom matInput [matDatepicker]="statuspickerfrom" formControlName="dteSearchStatusDateFrom">
              <mat-datepicker-toggle matSuffix [for]="statuspickerfrom"></mat-datepicker-toggle>
              <mat-datepicker #statuspickerfrom></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="search-option-row-4">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 200px !important;" appearance="fill">
              <mat-label>Status date to</mat-label>
              <input id="dteSearchStatusDateTo" class="date-input receipt-date-search" #searchReceiptDateTo matInput [matDatepicker]="statuspickerto" formControlName="dteSearchStatusDateTo">
              <mat-datepicker-toggle matSuffix [for]="statuspickerto"></mat-datepicker-toggle>
              <mat-datepicker #statuspickerto></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="search-option-row-5">
            <mat-form-field class="chh-form-field example-full-width header-button" style="width: 400px !important" appearance="fill">
              <mat-label>Hauling Company Name</mat-label>
              <input class="textbox-input" matInput placeholder="i.e. Blanchards" value="" type="text" formControlName="txtSearchHaulingCompanyName" (keydown.enter)="searchDeliveryDockets('')">
            </mat-form-field>
            <mat-icon matTooltip="separate multiple search text by a comma i.e. my search text,more search text... Also use an astrisk(*) as a wild card i.e T*xt" class="help-icon">help_outline</mat-icon>
          </div>

          <div class="search-option-row-6">
            <mat-form-field appearance="fill" class="chh-form-field">
              <mat-label>Yard Section</mat-label>
              <mat-select formControlName="intYardSection" multiple>
                <mat-option *ngFor="let yardSection of yardSections" [value]="yardSection.intValue">{{yardSection.txtValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="search-option-row-7">
            <span><mat-checkbox class="example-margin" #nextDelivery id="blnNextDelivery" formControlName="blnNextDelivery">Next Delivery</mat-checkbox></span>
          </div>

          <div class="search-field-wrapper">
            <div class="search-option-row-invoice-status">
              <label id="example-radio-group-label">Invoice Status</label><br />
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" #invoiceStatus formControlName="radInvoiceStatus">
                <mat-radio-button class="example-radio-button" [value]="0">N/A</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">To Be Emailed</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="2">Emailed</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-field-wrapper">
            <div class="search-option-row-invoice-uploaded">
              <label id="example-radio-group-label">Invoice Uploaded</label>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" #invoiceUploaded formControlName="radInvoiceUploaded">
                <mat-radio-button class="example-radio-button" [value]="0">N/A</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="1">To Be Uploaded</mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="2">Uploaded</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="search-option-row-7">
            <button class="search-option-delivery-docket-button" type="button" mat-flat-button color="primary" (click)="searchDeliveryDockets('')">Search</button>
          </div>

          <mat-divider></mat-divider>

        </div>
      </mat-tab>

      <mat-tab label="Create Delivery Docket" class="tab-label">
        <div class="create-delivery-docket-container">
          <app-delivery-docket-create (deliveryDocketCreated)="deliveryDocketCreated($event)" (deliveryDocketCancelled)="deliveryDocketCancelled($event)" *ngIf="selectedTab == eDeliveryDocketTabs.CreateDeliveryDocket"></app-delivery-docket-create>
        </div>
      </mat-tab>

      <mat-tab label="Update Delivery Docket" class="tab-label">
        <div class="update-delivery-docket-container">
          <app-delivery-docket-update [deliveryDocket]="deliveryDocket" [orderId]="orderId" (deliveryDocketUpdated)="deliveryDocketUpdated($event)" (deliveryDocketCancelled)="deliveryDocketCancelled($event)" *ngIf="selectedTab == eDeliveryDocketTabs.UpdateDeliveryDocket"></app-delivery-docket-update>
        </div>
      </mat-tab>

      <mat-tab label="Add Docket Items" class="tab-label">
        <div class="add-docket-items-container">
          <app-delivery-docket-add-items #deliveryDocketAddItems [deliveryDocket]="deliveryDocketAddItem" [columnListId]="'columnListId'" (deliveryDocketItemsAdded)="deliveryDocketItemsAdded($event)" (deliveryDocketItemsCancelled)="deliveryDocketItemsCancelled($event)" *ngIf="selectedTab == eDeliveryDocketTabs.DocketAddItems"></app-delivery-docket-add-items>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</form>


