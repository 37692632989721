import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../shared.service';
import { Printer } from '../../business-objects/printer.bo';
import { PrintByteArray } from '../../app-objects/PrintByteArray';
import { dtoKeyValueBarcode } from '../../business-objects/dto-int-key-value.bo';
import jsPDF from 'jspdf';
import { AlertService } from '../alert.service';

@Injectable()
export class PrintService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService
               , private alertService: AlertService) {

    this._baseUrl = baseUrl;

  }

  async printByteArray(printByteArray: PrintByteArray) {

    //const headers = new HttpHeaders({
    //  'Content-Type': 'application/octet-stream',
    //  'Accept': 'application/octet-stream'
    //});

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    this.http.post<Blob>("https://localhost:44358/api/Print/PrintByteArrayDirect/", printByteArray, { headers: headers }).subscribe(data => {   //, responseType: 'blob' as 'json'
      //console.log("data: " + data);
    });

  }


  async viewPDF(htmlElement: HTMLElement, width: number, scale: number) {

    return new Promise<boolean>(resolve => {
      if (htmlElement == null) {
        console.log("null element");
        resolve(false);
      }

      var pdf = new jsPDF('p', 'px', 'a4');

      pdf.html(htmlElement,
        {
          html2canvas: {
            // insert html2canvas options here, e.g.
            width: width,
            scale: scale
          },
          callback: (pdf) => {
            console.log("got pdf about to open");
            window.open(pdf.output('bloburl').toString());
            //pdf.save('save.pdf');
            console.log("pdf opened");
            resolve(true);
          }
        }
      );

    });
  }

  async printPDF(htmlElement: HTMLElement, printer: Printer, width: number, scale: number) {
    return new Promise<boolean>(resolve => {
    if (htmlElement == null) {
      console.log("null element");
      resolve(false);
    }

    var pdf = new jsPDF('p', 'px', 'Letter');

    pdf.html(htmlElement,
      {
        html2canvas: {
          // insert html2canvas options here, e.g.
          width: width,
          scale: scale
        },
        callback: (pdf) => {
          let arrBuffer: ArrayBuffer = pdf.output('arraybuffer');
          console.log(arrBuffer);

          let keyValueBarcode: dtoKeyValueBarcode = new dtoKeyValueBarcode;
          keyValueBarcode.intValueBarcode = arrBuffer

          const base64String = btoa(String.fromCharCode(...new Uint8Array(arrBuffer)));

          let printByteArray: PrintByteArray = new PrintByteArray();
          printByteArray.byteArray = base64String;
          printByteArray.printer = printer;

          console.log(printByteArray);


          this.printByteArray(printByteArray);
          resolve(true);
       }
      }
      );
    });

  }

  async getPrinterByNamePromise(name: string, type: number): Promise<Printer> {
    return new Promise<Printer>(resolve => {
      this.http.get<Printer>(this._baseUrl + 'api/AppPrinter/GetPrintersByAppNameAndType/' + name + '/' + type).subscribe(printer => {
        //console.log("treatment plant printer");
        //console.log(printer[0]);
        //console.log(JSON.stringify(printer[0]));

        resolve(printer[0]);

      }, err => {

        console.log(err);
        resolve(null)

      });

      return null;
    });

  }

  printPoleBarcode(poleNumber: string, printer: Printer) {
    let path: string = this.sharedService.PrintServerURI;
    //path = "https://localhost:44358/";

    let consoleOutput = document.getElementById("consoleOutput");

    //consoleOutput.innerText = "Testing output"; 

    console.log("consoleOutput", consoleOutput); 

    //https://localhost:44358
    //this.sharedService.PrintServerURI

    this.http.post(path + 'api/Print/PrintPoleBarcode/' + poleNumber.toString(), printer, { responseType: 'blob' }).subscribe(pdf => {
      //let mimeType: string = 'application/pdf';
      //let blob: any = new Blob([pdf], { type: mimeType });
      //const url = window.URL.createObjectURL(blob);
      //console.log(blob);
      //window.open(url);


      //saveAs(blob, 'testFile.txt');
      //saveAs(blob, 'testFile.jpg');
      //saveAs(blob, 'testPDF.pdf');

      this.alertService.openSnackBarDefault("The barcode has printed pole number " + poleNumber);


    }, (err: HttpErrorResponse) => {
      console.log(err);

      consoleOutput.innerText = "error: " + err.error + '\n';
      consoleOutput.innerText += "message: " + err.message + '\n';
      consoleOutput.innerText += "name: " + err.name + '\n';
      consoleOutput.innerText += "statusText: " + err.statusText + '\n';
      consoleOutput.innerText += "type: " + err.type + '\n';
      consoleOutput.innerText += "type: " + err.url + '\n';
      consoleOutput.innerText += "headers: " + err.headers + '\n';

      //console.log("consoleOutput", consoleOutput); 

      this.alertService.openSnackBarError("Error printing barcode\n" + err.error, "Close", "center", "bottom", 4000, true, err.error);

    });;
  }


}

