import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { AppUser } from "../app-users/app-user-update/appuser.module";
import { AppUser as AppUser2 } from '../_shared/business-objects/app-user.bo';
import { map, share, shareReplay, take, tap } from 'rxjs/operators'
import * as jwt_decode from 'jwt-decode';
//import { conditionallyCreateMapObjectLiteral } from "@angular/compiler/src/render3/view/util";
//import { error } from "@angular/compiler/src/util";
import { Router, RouterStateSnapshot, UrlSerializer } from '@angular/router';
import { AppUserService } from '../_shared/services/app-user.service';
import { BehaviorSubject, from, interval, Observable, Subject, timer } from "rxjs";
import { ActivatedRoute } from '@angular/router';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { AuthenticationResult, InteractionType, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { MsalService } from "@azure/msal-angular";
import { OAuthSettings } from "../account/oauth";
import { AlertService } from '../_shared/services/alert.service';
import { AlertsService } from '../alerts.service';
import { User } from "../account/user";

import { Client, GraphRequestCallback } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { dtoKeyValue } from "../_shared/business-objects/dto-int-key-value.bo";
import { ReckonBearerResponse } from "../_shared/reckon-objects/ReckonBearerResponse";
import { ReckonSettingsService } from "../_shared/services/reckon.service";


@Injectable()
export class AuthService {

  @Output() reckonAuth = new EventEmitter<boolean>();

  authRouter: Router;
  userService: AppUserService;
  user: AppUser;

  private authenticated = new BehaviorSubject<boolean>(false);
  private reckonAuthenticated = new BehaviorSubject<boolean>(false);
  private adminUser = new BehaviorSubject<boolean>(false);
  private office365Authenticated = new BehaviorSubject<boolean>(false);
  private _refreshingToken: boolean = false;
  private _refreshingReckonToken: boolean = false;
  private tokenTimer$ = new BehaviorSubject<string>("");
  private clock: Observable<Date>;
  private tokenToRefresh: Observable<string>;
  public _baseUrl: string;

  ms_authenticated: boolean = false;
  public ms_user?: User;
  public graphClient?: Client;
  authRes: AuthenticationResult;

  //public $visible = new Subject<number>();

  reckonBearerRes: ReckonBearerResponse = null;
  authCode: string = "";
  subKey: string = "";
  reckonRefreshToken: string = "";
  reckonAccessToken: string = "";
  reckonTokenExpiry: number = 0;
  basicToken: string = "";
  clientId: string = "";
  redirectURI: string = "";
  accessToken: string = "";


  get isAuthenticated() {
    //this.setLocation();

    this.setIsAuthenticated();
    return this.authenticated.asObservable();
  }

  get isReckonAuthenticated() {
    //this.setLocation();

    this.setIsReckonAuthenticated();
    return this.reckonAuthenticated.asObservable();
  }

  get isAdmin() {
    //this.getAdminUser();  // just let setSession decide if admin or not rather than second hit to DB.
    return this.adminUser.asObservable().pipe(shareReplay());
  }

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private appUserService: AppUserService,
    private serializer: UrlSerializer, private _snackBar: MatSnackBar, private msalService: MsalService, private alertService: AlertService, private alertsService: AlertsService
    , @Inject('BASE_URL') baseUrl: string, private reckonSettingsService: ReckonSettingsService) {
    this.authRouter = router;
    this.userService = appUserService;
    this._baseUrl = baseUrl;

    //this.tokenTimer();


    this.clock = interval(1000).pipe(
      map(
        tick => {
          return new Date();
        }
      ), share());

    this.tokenToRefresh = interval(500).pipe(
      map(
        tick => {
          return this.timeToRefreshToken();
        }
      ), share());
  }

  //// Prompt the user to sign in and
  //// grant consent to the requested permission scopes
  async signIn(): Promise<AuthenticationResult> {
    //var loginRequest = {
    //  scopes: ["user.read", "mail.send"] // optional Array<string>
    //};

    //console.log("location : ", document.location.href);
    if (document.location.href != "https://localhost:44356/" && document.location.href != "https://mtech.coffshardwoods.com.au/" && document.location.href != "https://mtech.testing.coffshardwoods.com.au/") {
      // We are not at the root so exit.
      //console.log("not at root");
      //return;
    }
    // 

    await this.msalService.initialize().toPromise();

    //const result2 = new Promise<AuthenticationResult>(async resolve => {
    //  await this.msalService.loginPopup(OAuthSettings).subscribe(res => {

    //    console.log(res);
    //    resolve(res);

    //  }, err => {
    //    console.log(err);
    //    this.alertService.openSnackBarError("Error getting pop up page", "Close", "center", "bottom", 4000, false, err.message);
    //    resolve(null);
    //  });
    //});

    //return result2;
    let result = null;

    try {
      //result = await this.msalService.acquireTokenSilent(OAuthSettings).toPromise();

      result = await this.acquireTokenSilent();

      if (result) {
          //console.log("got in Silently = ", result);
      }

      if (result == null) {
        //Try pop up logon.

        console.log("about to do pop up");

        result = await this.msLoginPopup();

        console.log("after pop up");

      }

    } catch (error) {
      //await this.msalService.loginPopup(OAuthSettings);
      //const result = await this.msalService.acquireTokenSilent(OAuthSettings).toPromise();
      //let tok = login.accessToken;

      
      result = await this.msalService
        .loginPopup(OAuthSettings)
        .toPromise()
        .catch((reason) => {
          console.log(JSON.stringify(reason, null, 2));
          let errMessage: string = "<br><br>Logged on as : " + this.getAppUser().txtUserName;
          errMessage += "<br><br>" + JSON.stringify(reason, null, 2);
          this.alertService.openSnackBarDefault(errMessage);
        });
      //this.alertService.openSnackBarError(JSON.stringify(reason, null, 2) + 'Login failed', 'Close', 'center', 'bottom', 4000, false, '');

      if (result) {
        //console.log("got token via popup = ", result);
      }

    }


    if (result) {
      this.msalService.instance.setActiveAccount(result.account);
      this.ms_authenticated = true;
      this.ms_user = await this.getUser();
      //console.log(result.expiresOn);
      //console.log(result.accessToken);
      return result;
    }
  }

  acquireTokenSilent() {
    return new Promise<AuthenticationResult>(resolve => {

      this.msalService.acquireTokenSilent(OAuthSettings).subscribe({
        next: authRes => {
          if (authRes != null) {
            //console.log("authRes: ", authRes);
            resolve(authRes);
          } else {
            resolve(null);
          }
        }, error: err =>{

          console.log("get silent token error: ", err);
          resolve(null);

        }
      });

    });



  }

  msLoginPopup() {
    return new Promise<AuthenticationResult>(resolve => {
    this.msalService
      .loginPopup(OAuthSettings)
      .subscribe({
        next: authRes => {

         console.log("loginPopup", authRes);
         resolve(authRes);

        }, error: err => {
          console.log(JSON.stringify(err, null, 2));
          let errMessage: string = "Logged on as : " + this.getAppUser().txtUserName;
          errMessage += "<br><br>" + err.error;

          this.alertService.openSnackBarError(JSON.stringify(err, null, 2), "Close", "center", "bottom", 0, true, errMessage);

        }
      });

    });

  }


  is365Authenticated(observer) {

    setTimeout(()=>{
      observer.next(this.ms_authenticated);
    }, 3000);

  }


  async isOffice365LoggedIn(): Promise<boolean> {

    //const agent = this.msalService.UserAgentApplication(...);
    //console.log("about to get user: ");
    const user = await this.getUser();
    let isLoggedIn = user != null;

    //console.log("isOffice365LoggedIn - user: ", user);
    return isLoggedIn;
    //return this.msalService.instance.getAllAccounts().length > 0;
  }

  

  async validateUser(authRes: AuthenticationResult) {
    if (authRes != null) {

      let keyValue: dtoKeyValue = new dtoKeyValue;
      keyValue.txtValue = authRes.accessToken;

      let headers = { "Accept": 'application/json', 'Content-Type': 'application/json' };
      //let headers = { "Accept": 'text/plain', 'Content-Type': 'text/plain' };

      //console.log("JSON.stringify(dtoHTML)", JSON.stringify(dtoHTML));

      this.http.post<AppUser>(this._baseUrl + 'api/MSGraph/CreateGrpahClient/', keyValue, { headers: headers }).subscribe(appUser => {
        //console.log("appUser", appUser);
        this.setSession(appUser);
      }, err => {
        console.log(err);
      });

      //this.http.get(this._baseUrl + 'api/MSGraph/' + encodeURIComponent(this.authRes.accessToken), { headers: headers, responseType: 'text' }).subscribe(msGraph => {
      //  console.log("msGraph", msGraph);

      //}, err => {
      //  console.log(err);
      //});

      /////5648236
      //this.http.post(this._baseUrl + 'api/MSGraph/ValidateUser', this.treatmentPlantPrinter, { responseType: 'text' }).subscribe(pdf => {
      //  //let mimeType: string = 'application/pdf';
      //  //let blob: any = new Blob([pdf], { type: mimeType });
      //  //const url = window.URL.createObjectURL(blob);
      //  console.log("pdf", pdf);
      //  //window.open(url);
      //  ////saveAs(blob, 'testFile.txt');
      //  ////saveAs(blob, 'testFile.jpg');
      //  ////saveAs(blob, 'testPDF.pdf');

      //}, err => {
      //  console.log(err);
      //});;


    }

  }

  // Sign out
  async signOut(): Promise<void> {
    await this.msalService.logoutPopup().toPromise();
    //await this.msalService.logout().toPromise();
    this.ms_user = undefined;
    this.ms_authenticated = false;
    await this.msalService.handleRedirectObservable();
  }

  // Clean up session
  async cleanUpSession(): Promise<void> {
    await this.msalService.handleRedirectObservable();
  }


  async getUser(): Promise<User | undefined> {
    if (!this.authenticated) return undefined;
    if (this.msalService.instance == null) {

    }

    //console.log("in authService.getUser");

    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: OAuthSettings.scopes,
        interactionType: InteractionType.Popup
      }
    );

    //console.log("about to initialize: ");

    await this.msalService.initialize().toPromise();


    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });

    //console.log("initialized: ");

    // Get the user from Graph (GET /me)
    const graphUser: MicrosoftGraph.User = await this.graphClient
      .api('/me')
      .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get().catch(err => {

        console.log("get user error: ", err);
        
        return null;

      });

    //console.log("********** in get user *************", graphUser);

    if (graphUser == null) {
      return null;
    }

    const user = new User();
    user.displayName = graphUser.displayName ?? '';
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail ?? graphUser.userPrincipalName ?? '';
    user.timeZone = graphUser.mailboxSettings?.timeZone ?? 'UTC';

    // Use default avatar
    user.avatar = '/assets/no-profile-photo.png';

    this.ms_user = user;

    return user;
  }

  graphResult(e) {
    console.log("in graph Result", e);
  }


  login(email: string, password: string) {

    this.user = new AppUser()
    this.user.txtUserName = email
    this.user.txtPassword = password
    this.user.txtFirstName = "";
    this.user.txtLastName = "";

    //console.log(JSON.stringify(this.user));

    //return this.http.post<any>('/api/login', { txtFirstName: "", txtLastName: "", txtUserName: email, txtPassword: password, txtSalt: "", txtToken: "", blnIsAdmin: false, blnIsPowerUser: false, guEmployeeId: "00000000-0000-0000-0000-000000000000" })
    //var user: AppUser = { txtFirstName: "", txtLastName: "", txtUserName: email, txtPassword: password, txtSalt: "", txtToken: "", blnIsAdmin: false, blnIsPowerUser: false, guEmployeeId: "00000000-0000-0000-0000-000000000000" }

    return this.http.post<AppUser>('/api/login', this.user)
    //.subscribe(authUser => {
    //  console.log("response: " + authUser);
    //  this.setSession(authUser);
    //}

    //  , (err: HttpErrorResponse) => {
    //    console.log("Message: " + err.message);
    //    console.log("Error: " + err.error);
    //    console.log("content-type : " + err.headers.get("content-type"));
    //    console.log("date: " + err.headers.get("date"));
    //    console.log("server : " + err.headers.get("server"));
    //    console.log("x-powered-by : " + err.headers.get("x-powered-by"));
    //    console.log("Cache-Control : " + err.headers.get("Cache-Control"));
    //    err.headers.keys().forEach(key => {
    //      console.log("Header Keys: " + key);

    //     })
    //    console.log("Status Code: " + err.status);
    //    console.log("Status Text: " + err.statusText);
    //});


    //this.getToken(email, password).subscribe(data => console.log(data));

    //.pipe(
    //  tap(res => this.setSession),
    //  shareReplay()
    // )

    // this is just the HTTP call, 
    // we still need to handle the reception of the token
    //.shareReplay();
  }

  async setReckonSession(rkBearerRes: ReckonBearerResponse) {
    localStorage.setItem('rkAccessToken', rkBearerRes.access_token);
    localStorage.setItem('rkRefreshToken', rkBearerRes.refresh_token);
    localStorage.setItem('rkTokenExpiryDate', new Date(Date.now() + (rkBearerRes.expires_in * 1000)).toString())

    this.reckonAuthenticated.next(true);

  }


  async setSession(user: AppUser) {
    var decoded: jwt_decode.JwtPayload = jwt_decode.default(user.txtToken);
    localStorage.setItem('txtFirstName', user.txtFirstName);
    localStorage.setItem('txtLastName', user.txtLastName);
    localStorage.setItem('txtEmail', user.txtUserName);
    localStorage.setItem('guEmployeeId', user.guEmployeeId);
    localStorage.setItem('guAppUserId', user.rowguid);
    localStorage.setItem('txtToken', user.txtToken);
    localStorage.setItem('dteExpiry', decoded.exp.toString());
    this.adminUser.next(user.blnIsAdmin);

    this.user = user;
    //console.log("this.user: ", this.user);

    this.authenticated.next(true);

    if (this.user.blnHas365Account == true && this.ms_authenticated == false) {
      //console.log("in set session - 365 not authenticated: " + this.ms_authenticated);
      await this.signIn();
    }

    //this.$visible.next(Math.random());

    //console.log("this.ms_authenticated: " + this.ms_authenticated);

    //console.log(

    //this.setLocation();

    //let returnUrl = await this.getReturnUrl();

    //this.authRouter.navigate([returnUrl])
    //this.authRouter.navigate(['/'])


    //   console.log("txtToken: " + localStorage.getItem("txtToken"));
    //   console.log("exp: " + new Date(Number(localStorage.getItem('dteExpiry')) * 1000));
    //   console.log("FirstName: " + localStorage.getItem("txtFirstName"));
    //   console.log("LastName: " + localStorage.getItem("txtLastName"));
    //   console.log("EmployeeId: " + localStorage.getItem("guEmployeeId"));
    //   console.log("txtEmail: " + localStorage.getItem("txtEmail"));
  }

  setLocation() {
    localStorage.setItem('loc', this.route.snapshot.queryParams['loc']);
    console.log("loc in setLocation: " + localStorage.getItem['loc']);
  }

  getAppUser() {
    return this.user;
  }

  getAppUserEmployeeId() {
    return localStorage.getItem("guEmployeeId");
  }

  getAppUserId() {
    return localStorage.getItem("guAppUserId");
  }



  //async getReturnUrl() {

  //  this.route.queryParams.subscribe(params => {
  //    if (params != null) {
  //      const tree = this.router.createUrlTree([], { queryParams: params });

  //      console.log("serializer: " + this.serializer.serialize(tree));
  //      let returnUrl: string = this.serializer.serialize(tree);
  //      return returnUrl;
  //    }
  //  }).unsubscribe();

  //}

  public async logout(state: RouterStateSnapshot) {
    localStorage.setItem('txtFirstName', '');
    localStorage.setItem('txtLastName', '');
    localStorage.setItem('txtEmail', '');
    localStorage.setItem('guEmployeeId', '');
    localStorage.setItem('guAppUserId', '');
    localStorage.setItem('txtToken', '');
    localStorage.setItem('dteExpiry', '');

    //console.log("auth.service state.url: " + state.url);
    //window.alert("auth.service state.url: " + state.url);

    this.adminUser.next(false);
    this.authenticated.next(false);


    this.route.queryParams.subscribe(params => {
      //let returnUrl: string = "";
      if (params != null) {

        //const tree = this.router.createUrlTree([], { queryParams: params });
        //let returnUrl: string = this.serializer.serialize(tree);

        //console.log("serializer: " + this.serializer.serialize(tree));
      }
      //this.authRouter.navigate(['login'], { queryParams: { returnUrl: state.url } })

    }).unsubscribe();


  }

  public async setLogoutSession() {
    localStorage.setItem('txtFirstName', '');
    localStorage.setItem('txtLastName', '');
    localStorage.setItem('txtEmail', '');
    localStorage.setItem('guEmployeeId', '');
    localStorage.setItem('guAppUserId', '');
    localStorage.setItem('txtToken', '');
    localStorage.setItem('dteExpiry', '');
    this.adminUser.next(false);
    this.authenticated.next(false);

    //this.authRouter.navigate(['/login'], { queryParams: { returnUrl: returnUrl } })  
  }

  public async setReckonLogoutSession() {
    localStorage.setItem('rkAccessToken', '');
    localStorage.setItem('rkRefreshToken', '');
    localStorage.setItem('rkTokenExpiry', '');
    this.reckonAuthenticated.next(false);
  }

  private setIsAuthenticated(): boolean {
    //console.log("in set Is Authenticated: " + (this.isTokenValid() == false));
    if (this.isTokenValid(false)) {
      this.authenticated.next(true);
      return true;
    }
    this.setLogoutSession();
    //this.logout()
    return false;
  }

  public isTokenValid(refreshNow: boolean): boolean {
    if ((localStorage.getItem("txtToken") != "") && localStorage.getItem('dteExpiry') != "") {

      if (new Date(Number(localStorage.getItem('dteExpiry')) * 1000) > new Date(Date.now())) {
        //console.log("token date: " + new Date(Number(localStorage.getItem('dteExpiry')) * 1000));
        let dateDiffMs: number = +(new Date(Number(localStorage.getItem('dteExpiry')) * 1000)) - +(new Date(Date.now()));
        let minutesToGo: number = Number((dateDiffMs / 60000).toFixed(2));
        //console.log("tokenMinutes: " + this.tokenMinutes());

        if ((refreshNow || minutesToGo < 25) && !this._refreshingToken) {
          this._refreshingToken = true;
          this.refreshToken().pipe(take(1)).subscribe(authUser => {
            //console.log("refreshed token: " + authUser);
            this.setSession(authUser);
            //this.openSnackBarError("Your token was refreshed.", "Close", "center", "bottom");
            this._refreshingToken = false;

          }, (errResponse: HttpErrorResponse) => {
            //this.openSnackBarError("There was an error refreshing your token.", "Close", "center", "bottom");
            //console.log("error refreshint token: ");
            this._refreshingToken = false;

          });
        }
        //console.log("ms diff: " + (dateDiffMs/60000).toFixed(2));
        //console.log("Expiry Date: " + new Date(Number(localStorage.getItem('dteExpiry')) * 1000));
        //console.log("token time offset: " + (new Date(Number(localStorage.getItem('dteExpiry')) * 1000)).getHours() + ":" + (new Date(Date.now())).getHours());  //
        //console.log("time offset: " + new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes());

        return true;
      }
    }
    return false;
  }

  tokenMinutes(): number {
    if (new Date(Number(localStorage.getItem('dteExpiry')) * 1000) > new Date(Date.now())) {
      let dateDiffMs: number = +(new Date(Number(localStorage.getItem('dteExpiry')) * 1000)) - +(new Date(Date.now()));
      let minutesToGo: number = Number((dateDiffMs / 60000).toFixed(2));
      //console.log("seconds: " + (parseFloat((minutesToGo % 1).toFixed(2)) * 60).toFixed(0));
      return minutesToGo;
    }
    return 0;
  }

  timeToRefreshToken(): string {
    //console.log((parseFloat((this.tokenMinutes() % 1).toFixed(2)) * 60).toFixed(0));
    //return Math.floor(this.tokenMinutes()) + ":" + (parseFloat((this.tokenMinutes() % 1).toFixed(2)) * 60).toFixed(0).padStart(2, "0");
    return Math.floor(this.tokenMinutes()).toString();
  }

  public refreshToken() {

    return this.http.get<AppUser>('/api/account/RefreshToken/' + localStorage.getItem('guEmployeeId'))

  }

  public refreshReckonToken() {
    //console.log("getReckonRefreshToken", new Date);

    let body = 'grant_type=refresh_token&refresh_token=' + this.reckonRefreshToken + '&redirect_uri=' + this.redirectURI;

    return this.http.post<ReckonBearerResponse>('https://identity.reckon.com/connect/token', body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + this.basicToken
      },
      responseType: 'json'
    });

  }

  getReckonToken() {
    //console.log("getReckonToken", new Date);

    let body = 'grant_type=authorization_code&code=' + this.authCode + '&redirect_uri=' + this.redirectURI;

    return this.http.post<ReckonBearerResponse>('https://identity.reckon.com/connect/token', body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + this.basicToken
      },
      responseType: 'json'
    });
  }

  private setIsReckonAuthenticated(): boolean {
    //console.log("in set Is Authenticated: " + (this.isTokenValid() == false));
    if (this.isReckonTokenValid(false)) {
      this.reckonAuthenticated.next(true);
      return true;
    }

    //this.setReckonLogoutSession();
    return false;
  }

  public reckonAccessTokenExpired() {
    if (localStorage.getItem('rkTokenExpiryDate') == null) {
      return true;
    }
    return new Date(localStorage.getItem('rkTokenExpiryDate')) < new Date(Date.now());
  }


  public isReckonTokenValid(refreshNow: boolean): boolean {

    if ((localStorage.getItem("rkRefreshToken") != "") && localStorage.getItem('rkTokenExpiry') != "") {

      //console.log("rkAccessToken", localStorage.getItem("rkAccessToken"));
      //console.log("rkTokenExpiryDate", localStorage.getItem('rkTokenExpiryDate'));
      //console.log("rkTokenExpiryDate", new Date(localStorage.getItem('rkTokenExpiryDate')));
      //console.log("new Date(Date.now())", new Date(Date.now()));

      //console.log("expiry greater than now date", (new Date(localStorage.getItem('rkTokenExpiryDate')) > new Date(Date.now())));

      //localStorage.setItem('rkTokenExpiryDate', new Date(Date.now() + (750 * 1000)).toString())

      if (new Date(localStorage.getItem('rkTokenExpiryDate')) > new Date(Date.now()) || refreshNow) {

        if (localStorage.getItem("rkAccessToken") == null || localStorage.getItem("rkAccessToken") == "") {
          //if some how the access token goes missing then get new one.
          //console.log("rkAccessToken is empty so refresh token");
          refreshNow = true;
        }

        let dateDiffMs: number = Number(new Date(localStorage.getItem('rkTokenExpiryDate'))) - Number(new Date(Date.now()));
        let minutesToGo: number = Number((dateDiffMs / 60000).toFixed(2));

        //console.log("dateDiffMs", dateDiffMs);
        //console.log("minutesToGo", minutesToGo);

        if ((refreshNow || minutesToGo < 10) && !this._refreshingReckonToken) {
          this._refreshingReckonToken = true;
          this.refreshReckonToken().pipe(take(1)).subscribe(rkBearerRes => {
            this.setReckonSession(rkBearerRes);
            this._refreshingReckonToken = false;
            this.reckonAuth.emit(true);

          }, (errResponse: HttpErrorResponse) => {
            //this.openSnackBarError("There was an error refreshing your token.", "Close", "center", "bottom");
            //console.log("error refreshint token: ");
            this._refreshingReckonToken = false;
            this.reckonAuth.emit(false);

          });
        }

        this.reckonAuth.emit(true);
        return true;
      }
    }

    if (localStorage.getItem("rkRefreshToken") == null || localStorage.getItem("rkRefreshToken") == ""){
      //Go to reckon-identity to get new tokens
      //console.log("refresh reckon token is empty");
      this.reckonAuth.emit(false);
    }

    return false;
  }
  //private getAdminUser() {
  //  this.adminUser.next(false);
  //  this.isAuthenticated.subscribe(data => {
  //    if (data) {
  //      this.http.get<AppUser>('/api/AppUser/GetAppUser/' + localStorage.getItem('guEmployeeId')).subscribe(
  //        data => {
  //        if (data.blnIsAdmin) {
  //          this.adminUser.next(true);
  //          return true;
  //        }
  //        }, (err: HttpErrorResponse) => {
  //         console.log("error: " + err.message)
  //      }

  //      );
  //    }
  //  });
  //}

  public registerUser(user: AppUser) {
    return this.http.post<AppUser>('/api/register', user)
  }

  public updateUser(user: AppUser2) {
    return this.http.put<AppUser2>('/api/register', user)
  }

  openSnackBarError(message: string, action: string, hPosition: MatSnackBarHorizontalPosition, vPosition: MatSnackBarVerticalPosition) {
    this._snackBar.open(message, action, {
      horizontalPosition: hPosition,
      verticalPosition: vPosition,
      duration: 4000,
      panelClass: ["snack-bar-error"]
    });
  }

  //tokenTimer(): Observable<string> {
  //  setTimeout(this.displayTime(), 1000);
  //  return this.displayTime();
  //}

  //displayTime() {

  //  return new Date(Date.now()).toDateString();
  //}

  getClock(): Observable<Date> {
    return this.clock;
  }

  getTokenMinutesToRefresh(): Observable<string> {
    return this.tokenToRefresh;
  }


  async loadReckonSettings() {

    this.reckonAccessToken = localStorage.getItem("rkAccessToken");
    this.reckonRefreshToken = localStorage.getItem("rkRefreshToken");
    this.reckonTokenExpiry = Number(localStorage.getItem("rkTokenExpiry"));

    this.basicToken = await this.reckonSettingsService.getReckonBasicTokenPromise();
    this.clientId = await this.reckonSettingsService.getReckonClientIdPromise();
    this.redirectURI = await this.reckonSettingsService.getReckonRedirectURIPromise();
    this.subKey = await this.reckonSettingsService.getReckonPrimarySubscriptionKeyPromise();

  }

  getReckonAccessToken() {
    //Set accesstoken from local storage if not populated.
    //console.log("getReckonAccessToken", this.reckonAccessToken);
    if (this.reckonAccessToken != localStorage.getItem("rkAccessToken")) {
      this.reckonAccessToken = localStorage.getItem("rkAccessToken");
      //console.log("getReckonAccessToken from local storage: ", this.reckonAccessToken);
    }

    return this.reckonAccessToken;
  }

}



//import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
//import { Injectable } from "@angular/core";
//import { AppUser } from "../app-user/appuser.module";
//import { share, shareReplay, tap } from 'rxjs/operators'
//import * as jwt_decode from 'jwt-decode';
//import { conditionallyCreateMapObjectLiteral } from "@angular/compiler/src/render3/view/util";
//import { error } from "@angular/compiler/src/util";
//import { Router } from '@angular/router';
//import { AppUserService } from '../app-user/app-user.service';
//import { BehaviorSubject, Observable } from "rxjs";



//@Injectable()
//export class AuthService {

//  authRouter: Router;
//  userService: AppUserService;
//  user: AppUser;
//  private authenticated = new BehaviorSubject<boolean>(false);
//  private adminUser = new BehaviorSubject<boolean>(false);

//  get isAuthenticated() {
//    this.setIsAuthenticated();
//    return this.authenticated.asObservable();
//  }

//  get isAdmin() {
//    //this.getAdminUser();  // just let setSession decide if admin or not rather than second hit to DB.
//    return this.adminUser.asObservable().pipe(shareReplay());
//  }

//  constructor(private http: HttpClient, private router: Router, private appUserService: AppUserService) {
//    this.authRouter = router;
//    this.userService = appUserService;

//  }

//  login(email: string, password: string) {

//      this.user = new AppUser()
//      this.user.txtUserName = email
//      this.user.txtPassword = password
//      this.user.txtFirstName = "";
//      this.user.txtLastName = "";


//    //return this.http.post<any>('/api/login', { txtFirstName: "", txtLastName: "", txtUserName: email, txtPassword: password, txtSalt: "", txtToken: "", blnIsAdmin: false, blnIsPowerUser: false, guEmployeeId: "00000000-0000-0000-0000-000000000000" })
//    //var user: AppUser = { txtFirstName: "", txtLastName: "", txtUserName: email, txtPassword: password, txtSalt: "", txtToken: "", blnIsAdmin: false, blnIsPowerUser: false, guEmployeeId: "00000000-0000-0000-0000-000000000000" }
    
//      return this.http.post<AppUser>('/api/login', this.user)
//        //.subscribe(authUser => {
//        //  console.log("response: " + authUser);
//        //  this.setSession(authUser);
//        //}
          
//        //  , (err: HttpErrorResponse) => {
//        //    console.log("Message: " + err.message);
//        //    console.log("Error: " + err.error);
//        //    console.log("content-type : " + err.headers.get("content-type"));
//        //    console.log("date: " + err.headers.get("date"));
//        //    console.log("server : " + err.headers.get("server"));
//        //    console.log("x-powered-by : " + err.headers.get("x-powered-by"));
//        //    console.log("Cache-Control : " + err.headers.get("Cache-Control"));
//        //    err.headers.keys().forEach(key => {
//        //      console.log("Header Keys: " + key);

//        //     })
//        //    console.log("Status Code: " + err.status);
//        //    console.log("Status Text: " + err.statusText);
//        //});
 

//    //this.getToken(email, password).subscribe(data => console.log(data));

//      //.pipe(
//      //  tap(res => this.setSession),
//      //  shareReplay()
//      // )

//      // this is just the HTTP call, 
//      // we still need to handle the reception of the token
//      //.shareReplay();
//  }

//  setSession(user: AppUser) {
//    var decoded: jwt_decode.JwtPayload = jwt_decode.default(user.txtToken);
//    localStorage.setItem('txtFirstName', user.txtFirstName);
//    localStorage.setItem('txtLastName', user.txtLastName);
//    localStorage.setItem('txtEmail', user.txtUserName);
//    localStorage.setItem('guEmployeeId', user.guEmployeeId);
//    localStorage.setItem('guAppUserId', user.rowguid);
//    localStorage.setItem('txtToken', user.txtToken);
//    localStorage.setItem('dteExpiry', decoded.exp.toString());
//    this.adminUser.next(user.blnIsAdmin);
//    this.authenticated.next(true);
//    //console.log("in set session:");
//    this.authRouter.navigate(['/'])


// //   console.log("txtToken: " + localStorage.getItem("txtToken"));
// //   console.log("exp: " + new Date(Number(localStorage.getItem('dteExpiry')) * 1000));
// //   console.log("FirstName: " + localStorage.getItem("txtFirstName"));
// //   console.log("LastName: " + localStorage.getItem("txtLastName"));
// //   console.log("EmployeeId: " + localStorage.getItem("guEmployeeId"));
// //   console.log("txtEmail: " + localStorage.getItem("txtEmail"));
// }

//  getAppUserEmployeeId() {
//    return localStorage.getItem("guEmployeeId");
//  }

//  getAppUserId() {
//    return localStorage.getItem("guAppUserId");
//  }

//  public logout() {
//    localStorage.setItem('txtFirstName', '');
//    localStorage.setItem('txtLastName', '');
//    localStorage.setItem('txtEmail', '');
//    localStorage.setItem('guEmployeeId', '');
//    localStorage.setItem('guAppUserId', '');
//    localStorage.setItem('txtToken', '');
//    localStorage.setItem('dteExpiry', '');
//    this.adminUser.next(false);
//    this.authenticated.next(false);
//    this.authRouter.navigate(['/login'])
//  }

//  private setIsAuthenticated(): boolean {
//    //console.log("in set Is Authenticated: " + (this.isTokenValid() == false));
//    if (this.isTokenValid()) {
//        this.authenticated.next(true);
//        return true;
//    }
//    this.logout()
//    return false;
//  }

//  public isTokenValid(): boolean {
//    if ((localStorage.getItem("txtToken") != "") && localStorage.getItem('dteExpiry') != "") {
//      if (new Date(Number(localStorage.getItem('dteExpiry')) * 1000) > new Date(Date.now())) {
//        //console.log("in hasTotkenExpired: ");
//        return true;
//      }
//    }
//    return false;
//  }

//  private getAdminUser() {
//    this.adminUser.next(false);
//    this.isAuthenticated.subscribe(data => {
//      if (data) {
//        this.http.get<AppUser>('/api/AppUser/GetAppUser/' + localStorage.getItem('guEmployeeId')).subscribe(
//          data => {
//          if (data.blnIsAdmin) {
//            this.adminUser.next(true);
//            return true;
//          }
//          }, (err: HttpErrorResponse) => {
//           console.log("error: " + err.message)
//        }

//        );
//      }
//    });
//  }

//  public registerUser(user: AppUser) {
//    return this.http.post<AppUser>('/api/register', user)
//  }
//}
