import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppPrinter } from '../../_shared/business-objects/app-printer.bo';
import { Printer } from '../../_shared/business-objects/printer.bo';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackBarCustomComponent } from '../../snack-bar-custom/snack-bar-custom.component';
import { Button } from 'protractor';
import { EmailService } from './email.service';
import { EmailMessage } from '../app-objects/email-message';
import { AuthService } from '../../account/auth.service';
import { AppUserInfoService } from './app-user-info.service';



@Injectable()
export class AlertService {

  public _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _snackBar: MatSnackBar, private emailService: EmailService) {
    this._baseUrl = baseUrl;
  }

  public openSnackBarCustomPromise(title: string, message: string, buttonText: string, button2Text: string, hPosition: MatSnackBarHorizontalPosition = "center", vPosition: MatSnackBarVerticalPosition = "bottom", panelclass: string = "", time: number = 0, showCancel: boolean = null) {
    return new Promise<string>(resolve => {
      let customSnack = this.openSnackBarCustom(title, message, buttonText, button2Text, hPosition, vPosition, panelclass, time, showCancel);
      customSnack.onAction().subscribe(data => {
        console.log(customSnack.instance.actionResponse);
        resolve(customSnack.instance.actionResponse);
      }, err => {
        resolve(err);
      });
    });
  }

  public openSnackBarCustomDefaultPromise(title: string, message: string, buttonText: string = "Ok", button2Text: string = "", hPosition: MatSnackBarHorizontalPosition = "center", vPosition: MatSnackBarVerticalPosition = "bottom", panelclass: string = "", time: number = 0, showCancel: boolean = false) {
    return new Promise<string>(resolve => {
      let customSnack = this.openSnackBarCustom(title, message, buttonText, button2Text, hPosition, vPosition, panelclass, time, showCancel);
      customSnack.onAction().subscribe(data => {
        console.log(customSnack.instance.actionResponse);
        resolve(customSnack.instance.actionResponse);
      }, err => {
        resolve(err);
      });
    });
  }

  public openSnackBarCustomDefaultShowCancel(title: string, message: string, buttonText: string = "Ok", button2Text: string = "", hPosition: MatSnackBarHorizontalPosition = "center", vPosition: MatSnackBarVerticalPosition = "bottom", panelclass: string = "", time: number = 0, showCancel: boolean = true) {
    return new Promise<string>(resolve => {
      let customSnack = this.openSnackBarCustom(title, message, buttonText, button2Text, hPosition, vPosition, panelclass, time, showCancel);
      customSnack.onAction().subscribe(data => {
        console.log(customSnack.instance.actionResponse);
        resolve(customSnack.instance.actionResponse);
      }, err => {
        resolve(err);
      });
    });
  }

  public openSnackBarCustom(title: string, message: string, buttonText: string, button2Text: string = "", hPosition: MatSnackBarHorizontalPosition = "center", vPosition: MatSnackBarVerticalPosition = "bottom", panelclass: string = "", time: number = 0, showCancel: boolean = null) {

    if (time == 0) {
      time = 4000;
    }

    if (panelclass == "") {
      panelclass = "centerSnackbar";
    }
    let data = new Object();
    if (title != "") {
      data['title'] = title;
    }

    data['message'] = message;
    data['actionText'] = buttonText;
    data['action2Text'] = button2Text;
    data['showCancel'] = showCancel;

    return this._snackBar.openFromComponent(SnackBarCustomComponent, {
      data: data,
      horizontalPosition: hPosition,
      verticalPosition: vPosition,
      //duration: time,
      panelClass: [panelclass]
      
    });
  }

  public openSnackBar(message: string, action: string, hPosition: MatSnackBarHorizontalPosition, vPosition: MatSnackBarVerticalPosition, panelclass: string, time: number) {
    if (time == 0) {
      time = 4000;
    }

    if (panelclass == "") {
      panelclass = "centerSnackbar";
    }
    
    return this._snackBar.open(message, action, {
      horizontalPosition: hPosition,
      verticalPosition: vPosition,
      duration: time,
      panelClass: [panelclass]
    });
  }

  public openSnackBarDefault(message: string, action: string = "Close", hPosition: MatSnackBarHorizontalPosition = "center", vPosition: MatSnackBarVerticalPosition = "bottom", panelclass: string = "", time: number = 0) {
    if (time == 0) {
      time = 4000;
    }

    if (panelclass == "") {
      panelclass = "centerSnackbar";
    }

    return this._snackBar.open(message, action, {
      horizontalPosition: hPosition,
      verticalPosition: vPosition,
      duration: time,
      panelClass: [panelclass]
    });
  }

  public openSnackBarError(message: string, action: string, hPosition: MatSnackBarHorizontalPosition, vPosition: MatSnackBarVerticalPosition, time: number, sendEmail: boolean, emailMessage: string) {
    if (time == 0) {
      time = 4000;
    }
    this._snackBar.open(message, action, {
      horizontalPosition: hPosition,
      verticalPosition: vPosition,
      duration: time,
      panelClass: ["snack-bar-error"]
    });

    if (sendEmail == true) {
      this.emailService.sendEmail(new EmailMessage("martin@coffshardwoods.com.au", "martin@coffshardwoods.com.au", "", "", "Web App Error", message + '\n' + emailMessage, null, true));
    }
  }

}
