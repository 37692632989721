export interface dtoOrderItem
{
	blnAmendment: boolean;
	blnBattens: boolean;
	blnBPFloorOrder: boolean;
	blnComplaint: boolean;
	blnComplaintResolved: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnDelivered: boolean;
	blnDontShowCube: boolean;
	blnDrilled: boolean;
	blnExistsOnDocket: boolean;
	blnFinalDelivery: boolean;
	blnInvoiced: boolean;
	blnJoinery: boolean;
	blnKilnDried: boolean;
	blnMinimumDeposit: boolean;
	blnNextBrisbaneDelivery: boolean;
	blnNextDelivery: boolean;
	blnNextDelivery2: boolean;
	blnNextLocalDelivery: boolean;
	blnNonStandardNominal: boolean;
	blnNonStandardProduct: boolean;
	blnNonStandardProductCode: boolean;
	blnNonStockItem: boolean;
	blnOptional: boolean;
	blnPaidInFull: boolean;
	blnPercisionDocked: boolean;
	blnPrecoated: boolean;
	blnPriceAmendmentRequested: boolean;
	blnPriceAmendmentUpdated: boolean;
	blnPricesAreCorrect: boolean;
	blnProductChangeRequested: boolean;
	blnProductChangeUpdated: boolean;
	blnQV: boolean;
	blnRegal: boolean;
	blnSetLength: boolean;
	blnShowAboveGroundDurability: boolean;
	blnShowInGroundDurability: boolean;
	blnShowStrengthGroup: boolean;
	blnStock: boolean;
	blnSubPole: boolean;
	dteComplaint: Date;
	dteComplaintResolved: Date;
	dteCreated: Date;
	dteDataUpdatedDate: Date;
	dteDateCreated: Date;
	dteDeliveryDate: Date;
	dteInvoiceDate: Date;
	dteLastUpdated: Date;
	dteProcessed: Date;
	dteStatusDate: Date;
	fltDiameter: number;
	fltDiscountPercentage: number;
	fltExThickness: number;
	fltExWidth: number;
	fltLength: number;
	fltLengthOLD: number;
	fltM3: number;
	fltMiscPrice: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltOriginalPrice: number;
	fltParquetryBoxes: number;
	fltPrecisionDockingRate: number;
	fltPrice: number;
	fltPriceIncGST: number;
	fltQtyAdded: number;
	fltQtyBal: number;
	fltQuantityDeliveried: number;
	fltQuantityOLD: number;
	fltQuantityPaid: number;
	fltQuantityTotal: number;
	fltSetupLMRate: number;
	fltThickness: number;
	fltTonne: number;
	fltTotalItemPrice: number;
	fltTotalItemPriceIncGST: number;
	fltWasteFactor: number;
	fltWidth: number;
	guAboveGroundDurabilityClassId: string;
	guClientDelAddressId: string;
	guClientDelAddressId2: string;
	guClientDelAddressId3: string;
	guClientDelAddressId4: string;
	guCustomerId: string;
	guCutbackPoleId: string;
	guDurabilityId: string;
	guElectricalAuthoritySpecId: string;
	guEmployeeChangedPriceId: string;
	guGradeId: string;
	guOrderId: string;
	guOriginalPoleId: string;
	guPoleId: string;
	guProductApplicationId: string;
	guProductCodeGuidId: string;
	guProductCodeId: string;
	guProductCodePriceChangeReasonId: string;
	guProductCodePriceId: string;
	guProductProfileId: string;
	guProductTypeId: string;
	guProfileId: string;
	guSpeciesId: string;
	guStockId: string;
	intBPFloorOrderItem: number;
	intComplaintStatus: number;
	intDiameterType: number;
	intDurabilityClassType: number;
	intFinalCount: number;
	intHazardLevel: number;
	intItemNo: number;
	intKN: number;
	intLaminatedBoards: number;
	intLength: number;
	intLengthType: number;
	intLogSection: number;
	intMiscPriceType: number;
	intMoistureLevel: number;
	intNoOfParquetryBoxes: number;
	intOrderStatus: number;
	intOrderType: number;
	intPriceType: number;
	intQuantityUoM: number;
	intRippedBoards: number;
	intSap: number;
	intSizeType: number;
	intSpec: number;
	intStatus: number;
	intStrengthGroup: number;
	intStructureGrade: number;
	intStuctureGrade: number;
	intTreatmentType: number;
	intWasteFactorType: number;
	rowguid: string;
	txtAboveGroundDurabilityClass: string;
	txtAdminComments: string;
	txtAuthorityCode: string;
	txtAuthorityName: string;
	txtComments: string;
	txtComplaintComments: string;
	txtCustomerName: string;
	txtDescription: string;
	txtDescriptionWeb: string;
	txtDiameterType: string;
	txtGradeAbbreviation: string;
	txtGradeName: string;
	txtInGroundDurabilityClass: string;
	txtInternalComments: string;
	txtInvoiceNo: string;
	txtMinimumDepositValue: number;
	txtMiscPriceComments: string;
	txtOrderNo: string;
	txtPackingInstruction: string;
	txtPoleLengthKN: string;
	txtPriceType: string;
	txtProductCodePriceChangeReason: string;
	txtProductName: string;
	txtProductShortName: string;
	txtProfileAbbrievation: string;
	txtProfileName: string;
	txtQuantityUoM: string;
	txtReceiptDescription: string;
	txtSizeType: string;
	txtSpeciesCode: string;
	txtSpeciesName: string;
	txtStockCode: string;
	txtStockKeepingUnit: string;
	txtStrengthGroup: string;
	txtStructureGrade: string;
}

export class dtoOrderItem
{
	blnAmendment: boolean;
	blnBattens: boolean;
	blnBPFloorOrder: boolean;
	blnComplaint: boolean;
	blnComplaintResolved: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnDelivered: boolean;
	blnDontShowCube: boolean;
	blnDrilled: boolean;
	blnExistsOnDocket: boolean;
	blnFinalDelivery: boolean;
	blnInvoiced: boolean;
	blnJoinery: boolean;
	blnKilnDried: boolean;
	blnMinimumDeposit: boolean;
	blnNextBrisbaneDelivery: boolean;
	blnNextDelivery: boolean;
	blnNextDelivery2: boolean;
	blnNextLocalDelivery: boolean;
	blnNonStandardNominal: boolean;
	blnNonStandardProduct: boolean;
	blnNonStandardProductCode: boolean;
	blnNonStockItem: boolean;
	blnOptional: boolean;
	blnPaidInFull: boolean;
	blnPercisionDocked: boolean;
	blnPrecoated: boolean;
	blnPriceAmendmentRequested: boolean;
	blnPriceAmendmentUpdated: boolean;
	blnPricesAreCorrect: boolean;
	blnProductChangeRequested: boolean;
	blnProductChangeUpdated: boolean;
	blnQV: boolean;
	blnRegal: boolean;
	blnSetLength: boolean;
	blnShowAboveGroundDurability: boolean;
	blnShowInGroundDurability: boolean;
	blnShowStrengthGroup: boolean;
	blnStock: boolean;
	blnSubPole: boolean;
	dteComplaint: Date;
	dteComplaintResolved: Date;
	dteCreated: Date;
	dteDataUpdatedDate: Date;
	dteDateCreated: Date;
	dteDeliveryDate: Date;
	dteInvoiceDate: Date;
	dteLastUpdated: Date;
	dteProcessed: Date;
	dteStatusDate: Date;
	fltDiameter: number;
	fltDiscountPercentage: number;
	fltExThickness: number;
	fltExWidth: number;
	fltLength: number;
	fltLengthOLD: number;
	fltM3: number;
	fltMiscPrice: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltOriginalPrice: number;
	fltParquetryBoxes: number;
	fltPrecisionDockingRate: number;
	fltPrice: number;
	fltPriceIncGST: number;
	fltQtyAdded: number;
	fltQtyBal: number;
	fltQuantityDeliveried: number;
	fltQuantityOLD: number;
	fltQuantityPaid: number;
	fltQuantityTotal: number;
	fltSetupLMRate: number;
	fltThickness: number;
	fltTonne: number;
	fltTotalItemPrice: number;
	fltTotalItemPriceIncGST: number;
	fltWasteFactor: number;
	fltWidth: number;
	guAboveGroundDurabilityClassId: string;
	guClientDelAddressId: string;
	guClientDelAddressId2: string;
	guClientDelAddressId3: string;
	guClientDelAddressId4: string;
	guCustomerId: string;
	guCutbackPoleId: string;
	guDurabilityId: string;
	guElectricalAuthoritySpecId: string;
	guEmployeeChangedPriceId: string;
	guGradeId: string;
	guOrderId: string;
	guOriginalPoleId: string;
	guPoleId: string;
	guProductApplicationId: string;
	guProductCodeGuidId: string;
	guProductCodeId: string;
	guProductCodePriceChangeReasonId: string;
	guProductCodePriceId: string;
	guProductProfileId: string;
	guProductTypeId: string;
	guProfileId: string;
	guSpeciesId: string;
	guStockId: string;
	intBPFloorOrderItem: number;
	intComplaintStatus: number;
	intDiameterType: number;
	intDurabilityClassType: number;
	intFinalCount: number;
	intHazardLevel: number;
	intItemNo: number;
	intKN: number;
	intLaminatedBoards: number;
	intLength: number;
	intLengthType: number;
	intLogSection: number;
	intMiscPriceType: number;
	intMoistureLevel: number;
	intNoOfParquetryBoxes: number;
	intOrderStatus: number;
	intOrderType: number;
	intPriceType: number;
	intQuantityUoM: number;
	intRippedBoards: number;
	intSap: number;
	intSizeType: number;
	intSpec: number;
	intStatus: number;
	intStrengthGroup: number;
	intStructureGrade: number;
	intStuctureGrade: number;
	intTreatmentType: number;
	intWasteFactorType: number;
	rowguid: string;
	txtAboveGroundDurabilityClass: string;
	txtAdminComments: string;
	txtAuthorityCode: string;
	txtAuthorityName: string;
	txtComments: string;
	txtComplaintComments: string;
	txtCustomerName: string;
	txtDescription: string;
	txtDescriptionWeb: string;
	txtDiameterType: string;
	txtGradeAbbreviation: string;
	txtGradeName: string;
	txtInGroundDurabilityClass: string;
	txtInternalComments: string;
	txtInvoiceNo: string;
	txtMinimumDepositValue: number;
	txtMiscPriceComments: string;
	txtOrderNo: string;
	txtPackingInstruction: string;
	txtPoleLengthKN: string;
	txtPriceType: string;
	txtProductCodePriceChangeReason: string;
	txtProductName: string;
	txtProductShortName: string;
	txtProfileAbbrievation: string;
	txtProfileName: string;
	txtQuantityUoM: string;
	txtReceiptDescription: string;
	txtSizeType: string;
	txtSpeciesCode: string;
	txtSpeciesName: string;
	txtStockCode: string;
	txtStockKeepingUnit: string;
	txtStrengthGroup: string;
	txtStructureGrade: string;
}

