export interface ClientDeliveryAddress
{
	blnActive: boolean;
	dteLastUpdated: Date;
	guClientId: string;
	rowguid: string;
	txtAddress: string;
	txtAddressCode: string;
	txtComments: string;
	txtContactFirstName: string;
	txtContactLastName: string;
	txtCountry: string;
	txtDeliveryEmailAddressCC: string;
	txtDeliveryEmailAddressTo: string;
	txtPostcode: string;
	txtSiteRegionName: string;
	txtState: string;
	txtTown: string;
}

export class ClientDeliveryAddress
{
	blnActive: boolean;
	dteLastUpdated: Date;
	guClientId: string;
	rowguid: string;
	txtAddress: string;
	txtAddressCode: string;
	txtComments: string;
	txtContactFirstName: string;
	txtContactLastName: string;
	txtCountry: string;
	txtDeliveryEmailAddressCC: string;
	txtDeliveryEmailAddressTo: string;
	txtPostcode: string;
	txtSiteRegionName: string;
	txtState: string;
	txtTown: string;
}

