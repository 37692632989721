import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { MatTab, MatTabChangeEvent, MatTabGroup, MatTabNav } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../account/auth.service';
import { FieldQueryParamMap } from '../../_shared/app-objects/field-query-param-map.ao';
import { DeliveryDocket } from '../../_shared/business-objects/delivery-docket.bo';
import { dtoDeliveryDocketOrderItem } from '../../_shared/business-objects/dto-delivery-docket-order-item.bo';
import { dtoDeliveryDocket } from '../../_shared/business-objects/dto-delivery-docket.bo';
import { dtoKeyValue } from '../../_shared/business-objects/dto-int-key-value.bo';
import { dtoProductType } from '../../_shared/business-objects/dto-product-type.bo';
import { dtoTPPoleInspectionItem } from '../../_shared/business-objects/dto-tppole-inspection-item.bo';
import { LoadingDocket } from '../../_shared/business-objects/loading-docket.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { AlertService } from '../../_shared/services/alert.service';
import { DeliveryDocketOrderItemService } from '../../_shared/services/delivery-docket-order-item.service';
import { DeliveryDocketService } from '../../_shared/services/delivery-docket.service';
import { dtoDeliveryDocketOrderItemService } from '../../_shared/services/dto-delivery-docket-order-item.service';
import { dtoDeliveryDocketService } from '../../_shared/services/dto-delivery-docket.service';
import { dtoProductTypeService } from '../../_shared/services/dto-product-type.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { UserPositionService } from '../../_shared/services/user-position.service';
import { DeliveryDocketAddItemsComponent } from '../delivery-docket-add-items/delivery-docket-add-items.component';
import { DeliveryDocketListComponent } from '../delivery-docket-list/delivery-docket-list.component';

export enum enDeliveryDocketTabs { DeliveryDockets = 0, SearchOptions = 1, CreateDeliveryDocket = 2, UpdateDeliveryDocket = 3, DocketAddItems = 4 }


@Component({
  selector: 'app-delivery-docket-search',
  templateUrl: './delivery-docket-search.component.html',
  styleUrls: ['./delivery-docket-search.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryDocketSearchComponent implements OnInit, AfterViewInit {

  @ViewChild('tabDeliveryDockets', { static: false }) tabDeliveryDockets: MatTabGroup;
  @ViewChild('deliveryDocketAddItems', { static: false }) deliveryDocketAddItems: DeliveryDocketAddItemsComponent;
  @ViewChild('appDeliveryDocketList', { static: false }) deliveryDocketList: DeliveryDocketListComponent;
  
  
  form: FormGroup;
  public eDeliveryDocketTabs = enDeliveryDocketTabs;

  selectedTab: number;
  showTable: boolean = false;
  showSpinner: boolean = false;
  deliveryDockets: MatTableDataSource<dtoDeliveryDocket> = new MatTableDataSource<dtoDeliveryDocket>();
  deliveryDocket: DeliveryDocket;
  openDeliveryDocket: dtoDeliveryDocket
  deliveryDocketAddItem: dtoDeliveryDocket;
  deliveryStatuses: dtoKeyValue[] = [];
  yardSections: dtoKeyValue[] = [];
  selectedStatuses: number[] = [];
  deliveryTypes: dtoKeyValue[] = [];
  selectedDeliveryType: number[] = [];
  orderId: string;
  invoicesToBeEmailed: boolean = false;
  invoicesToBeUploaded: boolean = false;
  officeAdmin: boolean = false;

  productTypes: dtoProductType[];
  selectedProd: dtoProductType[];

  filterToQueryParamMap: FieldQueryParamMap[] = [];

  search: boolean = false;

  constructor(private dtoDeliveryDocketService: dtoDeliveryDocketService, private deliveryDocketService: DeliveryDocketService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private route: ActivatedRoute, private formService: FormService, private dtoDeliveryDocketOrderItemService: dtoDeliveryDocketOrderItemService
    , private userPositionService: UserPositionService, private authService: AuthService, private cdref: ChangeDetectorRef, private productTypeService: dtoProductTypeService) {

    this.form = this.fb.group({
      txtSearchDeliveryDocketNo: [''],
      txtSearchLoadingDocketNo: [''],
      dteSearchStatusDateFrom: [''],
      dteSearchStatusDateTo: [''],
      txtSearchCustomer: [''],
      txtSearchOrderNumber: [],
      txtSearchHaulingCompanyName: [''],
      intStatus: [''],
      blnSearchUploadInvoice: [''],
      intDeliveryType: [''],
      intYardSection: [''],
      blnNextDelivery: [''],
      radInvoiceStatus: [''],
      radInvoiceUploaded: [''],
      txtSearchFinalNumber: [''],
      txtSearchLocation: ['']
    });

    this.deliveryStatuses = this.sharedService.getEnumObject2(DeliveryDocket.enDeliveryStatus);
    this.yardSections = this.sharedService.getEnumObject2(SharedService.enYardSection);
    this.deliveryTypes = this.sharedService.getEnumObject2(DeliveryDocket.enDeliveryType);

    this.filterToQueryParamMap.push({ txtField: "txtSearchDeliveryDocketNo", txtQueryParam: "docketno", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "intStatus", txtQueryParam: "status", intFieldType: FieldQueryParamMap.enFieldType.SelectboxMultiple, intValueType: FieldQueryParamMap.enValueType.Int });
    this.filterToQueryParamMap.push({ txtField: "intYardSection", txtQueryParam: "yardsection", intFieldType: FieldQueryParamMap.enFieldType.SelectboxMultiple, intValueType: FieldQueryParamMap.enValueType.Int });
    this.filterToQueryParamMap.push({ txtField: "intDeliveryType", txtQueryParam: "deliverytype", intFieldType: FieldQueryParamMap.enFieldType.SelectboxMultiple, intValueType: FieldQueryParamMap.enValueType.Int });
    this.filterToQueryParamMap.push({ txtField: "txtSearchHaulingCompanyName", txtQueryParam: "hauler", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "txtSearchCustomer", txtQueryParam: "customer", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "txtSearchOrderNumber", txtQueryParam: "orderno", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "txtSearchFinalNumber", txtQueryParam: "finalno", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "txtSearchLocation", txtQueryParam: "location", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.String });
    this.filterToQueryParamMap.push({ txtField: "dteSearchStatusDateFrom", txtQueryParam: "datefrom", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.Date });
    this.filterToQueryParamMap.push({ txtField: "dteSearchStatusDateTo", txtQueryParam: "dateto", intFieldType: FieldQueryParamMap.enFieldType.Textbox, intValueType: FieldQueryParamMap.enValueType.Date });
    this.filterToQueryParamMap.push({ txtField: "blnSearchUploadInvoice", txtQueryParam: "uploadinvoice", intFieldType: FieldQueryParamMap.enFieldType.SlideToogle, intValueType: FieldQueryParamMap.enValueType.Boolean });
    this.filterToQueryParamMap.push({ txtField: "blnNextDelivery", txtQueryParam: "nextdelivery", intFieldType: FieldQueryParamMap.enFieldType.Checkbox, intValueType: FieldQueryParamMap.enValueType.Boolean });
    this.filterToQueryParamMap.push({ txtField: "radInvoiceStatus", txtQueryParam: "invoicestatus", intFieldType: FieldQueryParamMap.enFieldType.Radiobox, intValueType: FieldQueryParamMap.enValueType.Int });
    this.filterToQueryParamMap.push({ txtField: "radInvoiceUploaded", txtQueryParam: "invoiceuploaded", intFieldType: FieldQueryParamMap.enFieldType.Radiobox, intValueType: FieldQueryParamMap.enValueType.Int });

    
  }

  ngOnInit(): void {

    document.title = "Delivery Dockets";

    this.showTable = false;

    this.selectedTab = this.eDeliveryDocketTabs.DeliveryDockets;

    this.loadData()

    this.selectedStatuses.length = 0;
    this.selectedDeliveryType.length = 0;


    //if (Array.isArray(this.form.controls['intStatus'].value)) {
    //  for (let i = 0; i <= this.form.controls['intStatus'].value.length - 1; i++) {
    //    this.selectedStatuses.push(this.form.controls['intStatus'].value[i]);
    //    //console.log("status: " + this.form.controls['intStatus'].value[i]);
    //  }

    //} else {
    //  this.selectedStatuses.push(this.form.controls['intStatus'].value)
    //}

    //if (Array.isArray(this.form.controls['intDeliveryType'].value)) {
    //  for (let i = 0; i <= this.form.controls['intDeliveryType'].value.length - 1; i++) {
    //    this.selectedDeliveryType.push(this.form.controls['intDeliveryType'].value[i]);
    //    console.log("intDeliveryType: " + this.form.controls['intDeliveryType'].value[i]);
    //  }

    //} else {
    //  this.selectedDeliveryType.push(this.form.controls['intDeliveryType'].value)
    //}



  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  ngAfterViewInit(): void {
    this.tabDeliveryDockets._tabHeader._alignInkBarToSelectedTab();

  }

  async loadData() {

    this.officeAdmin = await this.userPositionService.isInPosition("office admin", this.authService.getAppUserEmployeeId());

    this.route.queryParams.subscribe(async params => {

      if (params != null) {
        let tab: number = params["tab"];
        let docketNo: number = params["docketno"];
        let finalNo: number = params["finalno"];
        let invoiceStatus: number = params["invoicestatus"];
        let invoiceUploaded: number = params["invoiceuploaded"];

        if (tab != null) {
          this.selectedTab = tab;
        }

        if (docketNo != null) {
         let deliveryDocket: dtoDeliveryDocket = await this.dtoDeliveryDocketService.getDeliveryDocketPromiseByDocketNo(docketNo);
          if (deliveryDocket != null) {

            if (params["search"] == "true") {
              console.log("params",params);
              //this.deliveryDockets.push(deliveryDocket);

              this.deliveryDockets.data = [deliveryDocket];


              this.openDeliveryDocket = deliveryDocket;

              if (params["openorderitems"] == "true" && this.openDeliveryDocket != null) {
                this.deliveryDocketList.expandElement(this.openDeliveryDocket, 0);
              }

            }

            if (tab == this.eDeliveryDocketTabs.DocketAddItems) {

              this.deliveryDocketAddItem = deliveryDocket;
              this.deliveryDocketAddItems.deliveryDocket = this.deliveryDocketAddItem;
              this.deliveryDocketAddItems.loadDeliveryDocketData();
              this.deliveryDocketAddItems.loadDeliveryDocketOrderItems();

              setTimeout(() => {

                if (finalNo != null) {
                  this.deliveryDocketAddItems.selectedTab = this.deliveryDocketAddItems.eDeliveryDocketAddItemsTabs.AddFinalItems;
                  this.deliveryDocketAddItems.form.controls["txtSearchFinalNo"].setValue(finalNo);
                  this.deliveryDocketAddItems.searchFinalInspection(finalNo);
                }
              }, 1000);

              setTimeout(() => {
                this.tabDeliveryDockets._tabHeader._alignInkBarToSelectedTab();
                this.deliveryDocketAddItems.tabDeliveryDocketsAddItems._tabHeader._alignInkBarToSelectedTab();
              }, 2000);


            }


            //this.deliveryDocketAddItems.deliveryDocketPreviewComponent.deliveryOrderItemsDatasource = new MatTableDataSource<dtoDeliveryDocketOrderItem>();
            //this.deliveryDocketAddItems.deliveryDocketPreviewComponent.deliveryOrderItemsDatasource.data = await this.dtoDeliveryDocketOrderItemService.getdtoDeliveryDocketOrderItemByDocketIdPromise(deliveryDocket.rowguid);
            //console.log("deliveryOrderItemsDatasource: ", this.deliveryDocketAddItems.deliveryDocketPreviewComponent.deliveryOrderItemsDatasource.data);
          }
        }

        if (invoiceStatus != null || invoiceUploaded != null) {
          if (params["search"] == "true") {
            setTimeout(function (othis) { othis.searchDeliveryDockets('') }, 1000, this);
          }
        }

       
      }

      this.formService.setFieldsFromQueryParams(this.filterToQueryParamMap, params, this.form);

    });

    this.productTypeService.getdtoProductTypes().then(productTypes => {
      this.productTypes = productTypes;
    });

    this.formService.setAutoFocusTimeout("txtSearchDeliveryDocketNo");

  }

  selectedProductTypes(productTypes: dtoProductType[]) {

    this.selectedProd = productTypes;

  }

  testSetFields(params) {

  }

  onTabChange(e: MatTabChangeEvent) {
    this.selectedTab = e.index;

    //console.log(e.index == this.eDeliveryDocketTabs.SearchOptions);

    if (e.index == this.eDeliveryDocketTabs.SearchOptions) {
      this.formService.setAutoFocusTimeout("txtSearchCustomer");
    }

    if (e.index == this.eDeliveryDocketTabs.DeliveryDockets) {
      this.formService.setAutoFocusTimeout("txtSearchDeliveryDocketNo");
    }


  }

  async searchDeliveryDockets(searchType: string) {



    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    let docketNo: string = this.form.controls["txtSearchDeliveryDocketNo"].value;
    let loadingDocketNo: string = this.form.controls["txtSearchLoadingDocketNo"].value;
    let statusDateFrom: string = this.form.controls["dteSearchStatusDateFrom"].value;
    let statusDateTo: string = this.form.controls["dteSearchStatusDateTo"].value;
    let haulingCompanyName: string = this.form.controls["txtSearchHaulingCompanyName"].value;
    let customer: string = this.form.controls["txtSearchCustomer"].value;
    let finalno: string = this.form.controls["txtSearchFinalNumber"].value;
    let location: string = this.form.controls["txtSearchLocation"].value;
    let orderno: string = this.form.controls["txtSearchOrderNumber"].value;
    let status: number[] = this.form.controls["intStatus"].value
    let deliveryType: number[] = this.form.controls["intDeliveryType"].value
    let yardSection: number[] = this.form.controls["intYardSection"].value
    let nextDelivery: boolean = this.form.controls["blnNextDelivery"].value
    let invoiceStatus: number = this.form.controls["radInvoiceStatus"].value
    let invoiceUploaded: number = this.form.controls["radInvoiceUploaded"].value
    const datepipe: DatePipe = new DatePipe('en-AU');

    //console.log("status var: ", status);
    //console.log("status form: ", this.form.controls["intStatus"].value);
    //console.log(deliveryType);

    let filterBySystemCustomer: boolean = true

    if (searchType == "") {

      if (status.length > 0 || (status != null && status.toString() != "")) {
        if (Array.isArray(status) == true){
          for (let i = 0; i <= status.length - 1; i++) {
            aParam.push(new SQLParamArray("intStatus", status[i].toString()));
          }
          aParamList.push(aParam);
          //console.log("intStatus - parameter Array", aParam);
        }

        if (Array.isArray(status) == false) {
          aParam.push(new SQLParamArray("intStatus", status.toString()));
          aParamList.push(aParam);
          //console.log("intStatus - parameter", aParam);
        }

     }


      //console.log("docketNo: ", docketNo);

      if (docketNo != null && docketNo.toString().trim() != "") {
        aParam = [];
        if (docketNo.toString().indexOf(",") > -1) {
          let aDocketNos: string[] = docketNo.toString().split(",");
          for (let i = 0; i <= aDocketNos.length - 1; i++) {
            aParam.push(new SQLParamArray("intDocketNo", aDocketNos[i].trim()));
          }
        }
        else {
          aParam.push(new SQLParamArray("intDocketNo", docketNo.toString().trim()));
        }
        aParamList.push(aParam);
      }

      if (loadingDocketNo != "" && loadingDocketNo != null) {
        aParam = [];
        if (loadingDocketNo.indexOf(",") > -1) {
          let aLoadingDocketNos: string[] = loadingDocketNo.split(",");
          for (let i = 0; i <= aLoadingDocketNos.length - 1; i++) {
            aParam.push(new SQLParamArray("intLoadingDocketNo", aLoadingDocketNos[i]));
          }
        }
        else {
          aParam.push(new SQLParamArray("intLoadingDocketNo", loadingDocketNo.toString()));
        }
        aParamList.push(aParam);
      }

      if (statusDateFrom != "" && statusDateFrom != null) {
        aParam = [];
        aParam.push(new SQLParamArray("dteStatusDate", datepipe.transform(statusDateFrom, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.GreaterThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND));
        aParamList.push(aParam);
      }

      if (statusDateTo != "" && statusDateTo != null) {
        aParam = [];
        aParam.push(new SQLParamArray("dteStatusDate", datepipe.transform(statusDateTo, 'yyyy-MM-dd'), SQLParamArray.enSQLOperator.LessThanOrEqualTo, SQLParamArray.enSQLLogicalOperator.AND));
        aParamList.push(aParam);
      }

      if (haulingCompanyName != "" && haulingCompanyName != null) {
        aParam = [];
        if (haulingCompanyName.indexOf(",") > -1) {
          let aHaulingCompanyNames: string[] = haulingCompanyName.split(",");
          for (let i = 0; i <= aHaulingCompanyNames.length - 1; i++) {
            aParam.push(new SQLParamArray("txtHaulingCompanyName", aHaulingCompanyNames[i], SQLParamArray.enSQLOperator.LikeAny, SQLParamArray.enSQLLogicalOperator.AND));
          }
        }
        else {
          aParam.push(new SQLParamArray("txtHaulingCompanyName", haulingCompanyName.toString(), SQLParamArray.enSQLOperator.LikeAny, SQLParamArray.enSQLLogicalOperator.AND));
        }
        aParamList.push(aParam);
      }

      //console.log("customer: ", customer.toString());
      if (customer != null && customer != "") {
        aParam = [];
        if (customer.indexOf(",") > -1) {
          let aCustomers: string[] = customer.split(",");
          for (let i = 0; i <= aCustomers.length - 1; i++) {
            aParam.push(new SQLParamArray("txtCustomerName", aCustomers[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND));
          }
        }
        else {
          aParam.push(new SQLParamArray("txtCustomerName", customer.toString(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND));
        }
        aParamList.push(aParam);
      }

      if (orderno != "" && orderno != null) {
        aParam = [];
        if (orderno.indexOf(",") > -1) {
          let aOrderNos: string[] = orderno.split(",");
          for (let i = 0; i <= aOrderNos.length - 1; i++) {
            aParam.push(new SQLParamArray("txtOrderNo", aOrderNos[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 2));
          }
        }
        else {
          aParam.push(new SQLParamArray("txtOrderNo", orderno.toString(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 2));
        }
        aParamList.push(aParam);
      }

      if (finalno != "" && finalno != null) {
        aParam = [];
        if (finalno.indexOf(",") > -1) {
          let aFinalNos: string[] = finalno.split(",");
          for (let i = 0; i <= aFinalNos.length - 1; i++) {
            aParam.push(new SQLParamArray("intFinalNo", aFinalNos[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 3));
          }
        }
        else {
          aParam.push(new SQLParamArray("intFinalNo", finalno.toString(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 3));
        }
        aParamList.push(aParam);
      }

      if (location != "" && location != null) {
        aParam = [];
        if (location.indexOf(",") > -1) {
          let aLocations: string[] = location.split(",");
          for (let i = 0; i <= aLocations.length - 1; i++) {
            aParam.push(new SQLParamArray("txtLocation", aLocations[i], SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND));
          }
        }
        else {
          aParam.push(new SQLParamArray("txtLocation", location.toString(), SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND));
        }
        aParamList.push(aParam);
      }

      if (deliveryType.length > 0 || (deliveryType != null && deliveryType.toString() != "")) {

        if (Array.isArray(deliveryType) == true) {
          //If we are searching on Stock Transfer only then dont search by blnSystemCustomer = 0.
          //If the user has selected both delivery types [Delivery Type and Stock Transfer] then also dont search by blnSystemCustomer = 0

          if ((deliveryType.length == 1 && deliveryType[0] == DeliveryDocket.enDeliveryType.StockTransfer) || deliveryType.length == 2) {
            filterBySystemCustomer = false;
          }

          aParam = [];
          for (let i = 0; i <= deliveryType.length - 1; i++) {
            aParam.push(new SQLParamArray("intDeliveryType", deliveryType[i].toString()));
          }
          aParamList.push(aParam);
          //console.log("intDeliveryType", aParam);
        }

        if (Array.isArray(deliveryType) == false) {
          //If we are searching on Stock Transfer only then dont search by blnSystemCustomer = 0.
          //If the user has selected both delivery types [Delivery Type and Stock Transfer] then also dont search by blnSystemCustomer = 0

          if (parseInt(deliveryType.toString()) == DeliveryDocket.enDeliveryType.StockTransfer) {
            filterBySystemCustomer = false;
          }

          aParam = [];
          aParam.push(new SQLParamArray("intDeliveryType", deliveryType.toString()));
          aParamList.push(aParam);
          //console.log("intDeliveryType", aParam);
        }
      }

      if (yardSection.length > 0 || (yardSection != null && yardSection.toString() != "")) {
        if (Array.isArray(yardSection) == true) {

          aParam = [];
          for (let i = 0; i <= yardSection.length - 1; i++) {
            aParam.push(new SQLParamArray("intYardSection", yardSection[i].toString()));
          }
          aParamList.push(aParam);
          
        }

        if (Array.isArray(yardSection) == false) {
          aParam = [];
          aParam.push(new SQLParamArray("intYardSection", yardSection.toString()));
          aParamList.push(aParam);
          
        }
      }

      if (nextDelivery != null && nextDelivery == true) {

        aParam = [];
        aParam.push(new SQLParamArray("blnNextDelivery", nextDelivery.toString()));
        aParamList.push(aParam);

      }


      if (invoiceStatus != null && invoiceStatus > 0) {

        aParam = [];
        aParam.push(new SQLParamArray("intInvoiceStatus", invoiceStatus.toString()));
        aParamList.push(aParam);

      }

      if (invoiceUploaded != null && invoiceUploaded > 0) {

        aParam = [];
        aParam.push(new SQLParamArray("intInvoicePortalUploaded", invoiceUploaded.toString()));
        aParamList.push(aParam);

      }

    }

    if (this.selectedProd != null) {
      let productTypeIds: string[] = this.selectedProd != null ? this.selectedProd.map((a) => { return a.rowguid }) : [];
      aParam = [];
      for (let p = 0; p <= productTypeIds.length - 1; p++) {
        aParam.push(new SQLParamArray("guProductTypeId", productTypeIds[p].toString(), SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 2));
      }
      aParamList.push(aParam);
      //this.sqlParamService.addParameter(this.aQuoteParamList, productTypeIds, "guProductTypeId", false, SQLParamArray.enSQLOperator.Equals, SQLParamArray.enSQLLogicalOperator.AND, null, "", SQLParamArray.enSQLStatementType.SubQuerySQL, 1, "dtoOrderItem");
    }


    if (searchType == "readyfordispatch") {
      aParam.push(new SQLParamArray("intStatus", DeliveryDocket.enDeliveryStatus.ReadyForDispatch.toString()));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intDeliveryType", "0"));
      aParamList.push(aParam);


    }

    if (searchType == "scheduledloads") {
      aParam = [];
      aParam.push(new SQLParamArray("intLoadingDocketStatus", LoadingDocket.enLoadingDocketStatus.Scheduled.toString()));
      aParam.push(new SQLParamArray("intLoadingDocketStatus", LoadingDocket.enLoadingDocketStatus.Loaded.toString()));
      aParam.push(new SQLParamArray("intLoadingDocketStatus", LoadingDocket.enLoadingDocketStatus.Dispatched.toString()));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("intStatus", DeliveryDocket.enDeliveryStatus.ReadyForDispatch.toString()));
      aParamList.push(aParam);
    }

    if (searchType == "nextdelivery") {

      aParam = [];
      aParam.push(new SQLParamArray("blnNextDelivery", "true"));
      aParamList.push(aParam);

    }

    if (searchType == "invoicetobeemailed") {

      aParam = [];
      aParam.push(new SQLParamArray("intInvoiceStatus", "1"));
      aParamList.push(aParam);

    }

    if (searchType == "invoicetobeuploaded") {

      aParam = [];
      aParam.push(new SQLParamArray("intInvoicePortalUploaded", "1"));
      aParamList.push(aParam);

    }
    
    if (filterBySystemCustomer == true) {
      aParam = [];
      aParam.push(new SQLParamArray("blnSystemCustomer", "0"));
      aParam.push(new SQLParamArray("blnSystemCustomer", "", SQLParamArray.enSQLOperator.IsNull));
      aParamList.push(aParam);
    }


    //console.log("aParam: ", aParam);

    this.showTable = false;

    if (aParamList.length == 0) {
      this.alertService.openSnackBar("Please select some search crieteria before searching", "Close", "center", "bottom", "centerSnackbar", 4000);
    }

    this.showSpinner = true;

    if (this.tabDeliveryDockets.selectedIndex != 0) {
        this.formService.setAutoFocusTimeout("btnSearchOptions");

        //document.getElementById("btnSearchOptions").focus();
        this.tabDeliveryDockets.selectedIndex = 0;

    }

    //console.log(aParamList);

    this.deliveryDockets.data = await this.dtoDeliveryDocketService.getDeliveryDocketPromiseParamArrayPromise(aParamList);
    this.deliveryDockets.data = this.deliveryDockets.data.sort((a, b) => { return a.intLoadingDocketNo - b.intLoadingDocketNo });

    console.log(this.deliveryDockets);

    //this.showTable = true
    this.showSpinner = false;

  }


  async editDeliveryDocket(deliveryDocket: dtoDeliveryDocket) {

    this.deliveryDocket = await this.deliveryDocketService.getDeliveryDocketPromise(deliveryDocket.rowguid);

    
    this.tabDeliveryDockets.selectedIndex = this.eDeliveryDocketTabs.UpdateDeliveryDocket;

  }

  async deliveryDocketCreated(deliveryDocketCreatedObj: [DeliveryDocket, string]) {
    if (deliveryDocketCreatedObj == null) {
      return;
    }

    if (deliveryDocketCreatedObj[1] == "create") {
      this.tabDeliveryDockets.selectedIndex = this.eDeliveryDocketTabs.DeliveryDockets;
      this.form.controls['txtSearchDeliveryDocketNo'].setValue(deliveryDocketCreatedObj[0].intDocketNo.toString());
      this.searchDeliveryDockets("");
    }

    //if (deliveryDocketCreatedObj[1] == "createanditem") {
    //  this.deliveryDocketAdd = await this.dtoDeliveryDocketService.getdtoDeliveryDocketPromise(deliveryDocketCreatedObj[0].rowguid);
    //  this.tabDeliveryDockets.selectedIndex = this.eDeliveryDocketTabs.DeliveryDockets;
    //  this.form.controls['txtSearchFinalNumber'].setValue(deliveryDocketCreatedObj[0].intFinalNo);
    //  console.log("before - this.autoAdd: " + this.autoAdd);
    //  await this.searchDeliveryDockets();
    //  console.log("after - this.autoAdd: " + this.autoAdd);

    //  this.appDeliveryDocketList.addDeliveryDocketItem(this.deliveryDocketAdd);

    //}

  }

  async deliveryDocketUpdated(deliveryDocket: DeliveryDocket) {

    if (deliveryDocket == null) {
      return;
    }

    this.tabDeliveryDockets.selectedIndex = this.eDeliveryDocketTabs.DeliveryDockets;

    this.form.controls['txtSearchDeliveryDocketNo'].setValue(deliveryDocket.intDocketNo);

    this.searchDeliveryDockets("");

  }

  deliveryDocketCancelled(cancelled: boolean) {
    this.tabDeliveryDockets.selectedIndex = this.eDeliveryDocketTabs.DeliveryDockets;
  }

  //updateDeliveryDocket(deliveryDocket: dtoDeliveryDocket, eStatus: DeliveryDocket.enDeliveryStatus, index: number) {

  //  console.log(deliveryDocket);


  //  this.deliveryDocketService.getDeliveryDocket(deliveryDocket.intDocketNo).subscribe(dd => {


  //    dd.intStatus = eStatus;
  //    this.deliveryDocketService.updateDeliveryDocket(dd).subscribe(ddUpdated => {

  //      this.deliveryDockets.splice(index, 1);
  //      //this.tblDeliveryDocket.renderRows();

  //    }, err => {
  //      console.log(err);

  //    });

  //  }, err => {
  //    console.log(err);
  //  });
  //}


  deliveryDocketItemsAdded(added: boolean) {

    

  }


  deliveryDocketItemsCancelled(e) {

  }

  addDeliveryDocketItems(deliveryDocket: dtoDeliveryDocket) {

    this.deliveryDocketAddItem = deliveryDocket;

    this.tabDeliveryDockets.selectedIndex = this.eDeliveryDocketTabs.DocketAddItems;


  }

}
