import { Injectable, Inject } from '@angular/core';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { dtoIntKeyValue, dtoKeyValue } from '../business-objects/dto-int-key-value.bo';

@Injectable()
export class SharedService {

  readonly aest_ms_offset: number = 36000000;  //milliseconds of +10:00 hours.
  readonly GST: number = 0.1;
  readonly GSTPlusOne: number = 1.1;
  readonly M3ToWeightConversion: number = 1.2;
  readonly M3ToWeightConversionHeavy: number = 1.27;

  readonly EmptyGuid: string = "00000000-0000-0000-0000-000000000000";
  public readonly GlobalCustomerGuid: string = "11111111-1111-1111-1111-111111111111";

  //=========================================================================================
  // WHEN USING THE SAMSUNG GALAXAY TAB YOU "MUST" TURN OFF 'PRIVATE DNS' UNDER
  // SETTINGS > CONNECTIONS > MORE CONNECTION SETTINGS > PRIVATE DNS
  // OTHERWISE THE CALL TO "https://mtech.utilities.coffshardwoods.com.au:8172/"
  // WILL NOT WORK ON THE SAMSUNG GALAXY TAB TABLET!!!!!
  // TOOK ME FOREVER TO FIND THIS OUT SO WRITTING NOTE HERE SO I DONT FORGET. 1/3/2024.
  //=========================================================================================

  readonly PrintServerURI: string = "https://mtech.utilities.coffshardwoods.com.au:8172/";

  //=========================================================================================

  readonly LocalServerURI: string = "https://localhost:44356";

  readonly MinDate: string = "0001-01-01T00:00:00";
  
  convertGuid(guid: string) {
    if (guid == "") {
      return this.EmptyGuid;
    }
    return guid;
  }

  cString(val) {
    if (val == null) {
      return "";
    }

    return val.toString();
  }

  cEmptyGuid(val) {
    if (val == null) {
      return this.EmptyGuid;
    }

    return String(val);
  }

  cNullGuid(val) {
    if (val == this.EmptyGuid) {
      return null;
    }

    return val;
  }

  camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  currentDatePlusTZOffset(): Date {
    // When sending data to server javascript serializes the current date to UTC.
    // So we need to add +10 hours so that the server receives it in AEST to be stored.
    // All the database date and time fields are in AEST.
    // Just remember that this is only used to send to the server and not to
    // be used with in application because it has added offset.
    return new Date(Date.now() + this.aest_ms_offset);
  }

  convertDateAEST(date: Date) {
    // If we want to store the date as Midnight in the server i.e no time
    // we have to set the time as +10:00 i.e 17/09/2023 10:00AM.
    // when the date gets sent to the server it is serialized to UTC there for removing 10 hours.
    // which will set it as midnight.
    // the getTimezoneOffset returns current time zone offset including if it's daylight savings.
    // the Math.abs converts the -600 to positive so we can 'add' the time too the date.
    // first you have to set the time to zero.


    if (this.isValidDate(date) == false) {
      //console.log("isValidDate: ", false);
      return null;
    }

    //console.log("date - after: ", date);
    date = new Date(date);
    //console.log("date - before: ", date);

    date.setHours(0, 0, 0, 0);
    date = new Date(date.getTime() + Math.abs(date.getTimezoneOffset()) * 60 * 1000)
    return date;
  }

  isValidDate(value): boolean {

    if (value == null || value == "") {
      return false;
    }

    let dateWrapper = new Date(value);
    //console.log(!isNaN(dateWrapper.getDate()));
    return !isNaN(dateWrapper.getDate());
  }

  // Returns an array from an enum 
  getEnumObject(enumObj) {

    let enumObjValues = Object.values(enumObj);
    let o: { key: number, value: string };
    let objs: [{ key: number, value: string }]
    objs = [{ key: 0, value: "" }];
    objs.pop();

    let iCount: number = 0;
    Object.values(enumObj).forEach(elem => {

      if (iCount <= (enumObjValues.length / 2) - 1) {
        objs.push({ key: iCount, value: elem.toString() });

        //console.log(objs[iCount]);
      }
      ++iCount;
    })

    return objs;
  }

  // Returns an array of dtoKeyValue object from an enum 
  getEnumObject2(enumObj, spaceBeforeCapitals: boolean = false) {

    let enumObjValues = Object.values(enumObj);
    let o: dtoKeyValue;
    let objs: dtoKeyValue[] = [];

    let iCount: number = 0;
    Object.values(enumObj).forEach((elem:string) => {

      if (iCount <= (enumObjValues.length / 2) - 1) {
        o = new dtoKeyValue();
        o.intValue = iCount;

        if (spaceBeforeCapitals == true) {
          o.txtValue = elem.replace(/([a-z])([A-Z])/g, '$1 $2');
        }
        if (spaceBeforeCapitals == false) {
          o.txtValue = elem.toString();
        }
        objs.push(o);

        //console.log(objs[iCount]);
      }
      ++iCount;
    })

    return objs;
  }


  // Returns an array of dtoKeyValue object from an enum 
  getEnumObjectKeyValue(enumObj, spaceBeforeCapitals: boolean = false) {

    let enumObjValues = Object.values(enumObj);
    let o: dtoIntKeyValue;
    let objs: dtoIntKeyValue[] = [];

    let iCount: number = 0;
    Object.values(enumObj).forEach(elem => {

      if (iCount <= (enumObjValues.length / 2) - 1) {

        if (spaceBeforeCapitals == true) {
          elem = elem.toString().replace(/([a-z])([A-Z])/g, '$1 $2');
        }

        o = new dtoIntKeyValue(iCount, elem.toString());
        objs.push(o);
      }
      ++iCount;
    })

    return objs;
  }

  // Returns an array of dtoKeyValue object from a string array
  getArrayKeyValue(ar: string[], spaceBeforeCapitals: boolean = false) {

    let enumObjValues = Object.values(ar);
    let o: dtoIntKeyValue;
    let objs: dtoIntKeyValue[] = [];

    let iCount: number = 0;
    Object.values(ar).forEach(elem => {

      //if (iCount <= (enumObjValues.length / 2) - 1) {

        if (spaceBeforeCapitals == true) {
          elem = elem.toString().replace(/([a-z])([A-Z])/g, '$1 $2');
        }

        o = new dtoIntKeyValue(iCount, elem.trimStart().toString());
        objs.push(o);
      //}
      ++iCount;
    })

    return objs;
  }

  dataURLToBlob(dataURL) {

    var parts = dataURL.split(';base64,');
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  public calcRoundM3(dia: number, diaType: SharedService.enDiameterType, length: number, glLength: number, round: number): number {

    if (dia == 0 || length == 0) {
      return 0;
    }


    if (diaType == SharedService.enDiameterType.Centre) {
      return Number(((Math.pow(((dia / 1000) / 2), 2) * Math.PI) * length).toFixed(round));
    }

    if (diaType == SharedService.enDiameterType.SED) {
      // iDia = (iDia + ((iLen / 2) * 10))
      //  (((iDia / 1000) / 2) ^ 2 * Math.PI * iLen)
      let iDia = (dia + ((length / 2) * 10));
      return Number(((Math.pow(((iDia / 1000) / 2), 2) * Math.PI) * length).toFixed(round));
    }

    if (diaType == SharedService.enDiameterType.BED) {
      // iDia = (iDia - (((iLen / 2) * 0.008) * 1000))  'use 0.008 taper for BED.
      //  (((iDia / 1000) / 2) ^ 2 * Math.PI * iLen)
      let iDia = (dia - (((length / 2) * 0.008) * 1000));
      return Number(((Math.pow(((iDia / 1000) / 2), 2) * Math.PI) * length).toFixed(round));
    }

    if (diaType == SharedService.enDiameterType.GL) {
      if (glLength == 0) {
        return 0;
      }

      // iDia = Math.Round(iDia + (doGLLength * 10), iRound)  'get butt dia
      // iDia = (iDia - (((iLen / 2) * 0.008) * 1000))  'use 0.008 taper for BED.
      let iDia = Number((dia + (glLength * 10)).toFixed(round));
      iDia = (iDia - (((length / 2) * 0.008) * 1000));
      return Number(((Math.pow(((iDia / 1000) / 2), 2) * Math.PI) * length).toFixed(round));
    }

  }

  RoundNumber(val, decimals): number {
    return Number(Number(val).toFixed(decimals))
  }

  spaceBeforeCapital(str: string) {
    return str.replace(/(_|-)/g, ' ')
      .trim()
      .replace(/\w\S*/g, function (str) {
        return str.charAt(0).toUpperCase() + str.substr(1)
      })
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  }

  getFirstItemArray(arr) {
    if (Array.isArray(arr) && arr.length > 0) {
      return arr[0];
    }
    return null;
  }

}

export namespace SharedService {
  export enum enHazardTreatment { None, H1, H2, H3, H4, H5, H6 }
  export enum enTreatmentType { None, CCA, Boron, ACQ }
  export enum enDiameterType { None, Centre, SED, BED, GL}
  export enum enPoleSpec { NONE = -1, NSW = 0, QLD = 1, VIC = 2 }
  export enum enStates { NSW = 0, QLD = 1, VIC = 2, SA = 3, WA = 4, NT = 5, TAS = 6, ACT = 7 }
  export enum enAppType {MTECH_Desktop_App = 0, MTECH_Web_App = 1, MTECH_POS_App = 2, MTECH_Utilities_App = 3, MTECH_Service_App = 4 }

  export enum enYardSection {
    BoardPlant = 0,
        LogYard = 1,
        Mill = 2,
        TreatmentPlant = 3,
        XArms = 4,
        Workshop = 5,
        Vehicles = 6,
        Office = 7,
        All = 8,
        Transport = 9,
        Harvesting = 10,
        Maintenance = 11,
        Site = 12,
        Farm = 13,
        Joinery = 14,
        Raymac = 15,
        Parquetry = 16,
        ToolHandles = 17,
        LawsonCresShowRoom = 18,
        Desap = 19,
        KilnDrying = 20,
        BPYard = 21,
        Mill2 = 22
  }
}
