import { Injectable, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Injectable()
export class BPStocktakeService {

  public _baseUrl: string;



  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._baseUrl = baseUrl;
  }


  getStocktake() {

    return this.http.get(this._baseUrl + 'api/bpstocktake');   //, { headers: headers }
  }

  getStocktakePack(identifier, sGeoLocationId) {
    return this.http.get(this._baseUrl + 'api/bpstocktake/GetPack/' + identifier + '/' + sGeoLocationId);
  }

  getTop20Stocktake(identifier: number, location: string, includeNonStocktake: boolean, sGeoLocationId: string) {
    if (location == '') {
      return this.http.get(this._baseUrl + 'api/bpstocktake/GetTop20Stocktake/' + identifier + '/' + includeNonStocktake + '/' + sGeoLocationId);
    }
    else {
      return this.http.get(this._baseUrl + 'api/bpstocktake/GetTop20Stocktake/' + identifier + '/' + location + '/' + includeNonStocktake + '/' + sGeoLocationId);
    }
  }

  getStock(identifier: number, location: string, includeNonStocktake: boolean, sGeoLocationId: string) {
    if (location == '') {
      console.log("location", location);
      return this.http.get(this._baseUrl + 'api/bpstocktake/GetStock/' + identifier + '/' + includeNonStocktake + '/' + sGeoLocationId);
    }
    else {
      return this.http.get(this._baseUrl + 'api/bpstocktake/GetStock/' + identifier + '/' + includeNonStocktake + '/' + sGeoLocationId + '/' + location);
    }
  }

  setStocktake(identifier: number, location: string, gEmployeeId: string) {
    //var idObj = {
    //  "txtName": "test",
    //  "txtIdentifier": "",
    //  "txtComments": "",
    //  "intContainerTypeSize": 0,
    //  "dteLastUpdated": "2021-10-04",
    //  "rowguid": "00000000-0000-0000-0000-000000000000"
    //};

    var idObj = {
      "intIdentifier": Number(identifier),
      "guStockContainerTypeId": "00000000-0000-0000-0000-000000000000",
      "guParentId": null,
      "dteCreated": "2021-10-04",
      "intStatus": 0,
      "dteLastModified": "2021-10-04",
      "dteReadyForDispatch": null,
      "dteDispatched": null,
      "dteDeliveredDate": null,
      "dteReturned": null,
      "dteArchivedDate": null,
      "txtArchiveComments": null,
      "txtLocation": location,
      "blnLawsonCres": null,
      "blnArchived": null,
      "blnDelivered": null,
      "blnMelbourne": null,
      "blnConsignment": null,
      "blnOnHold": null,
      "blnFollowUp": null,
      "txtComments": null,
      "txtStickerComments": null,
      "blnMelbourneStockDelivered": null,
      "blnConsignmentDelivered": null,
      "guEmployeeOnHoldId": null,
      "dteOnHold": null,
      "dteFollowUp": null,
      "blnStockTake": null,
      "txtHoldComments": null,
      "dteStockTake": null,
      "blnUncommitted": null,
      "blnParentPack": null,
      "dteLastUpdated": "2021-10-04",
      "rowguid": "00000000-0000-0000-0000-000000000000"
    };
    return this.http.put<any>(this._baseUrl + 'api/bpstocktake/SetPack/' + gEmployeeId, idObj);
  }

}  
