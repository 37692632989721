export interface ProductType
{
	blnActive: boolean;
	blnBlockParquetry: boolean;
	blnBoardPlant: boolean;
	blnBoardPlantPL: boolean;
	blnBoardPlantPopular: boolean;
	blnBPFloorProduct: boolean;
	blnCHHManufactured: boolean;
	blnCustomParquetry: boolean;
	blnCutByMill: boolean;
	blnDesap: boolean;
	blnDesapPL: boolean;
	blnForestry: boolean;
	blnJoinery: boolean;
	blnKilnDried: boolean;
	blnLaminated: boolean;
	blnLogYard: boolean;
	blnMaintenance: boolean;
	blnMill: boolean;
	blnMillPL: boolean;
	blnOrders: boolean;
	blnParquetry: boolean;
	blnPoleCosting: boolean;
	blnPoleFinalInspection: boolean;
	blnPPToeDia: boolean;
	blnPrivateProperty: boolean;
	blnProductApplication: boolean;
	blnRequiresDurability: boolean;
	blnSawLog: boolean;
	blnStock: boolean;
	blnStockListAddDeck: boolean;
	blnStockListAddOverlay: boolean;
	blnStockListAddTG: boolean;
	blnToolHandle: boolean;
	blnTreatmentPL: boolean;
	blnTreatmentPlant: boolean;
	blnTrimming: boolean;
	blnUsuallySetLength: boolean;
	blnWeightCalculator: boolean;
	blnXArm: boolean;
	dteLastUpdated: Date;
	fltMaxAvgLength: number;
	fltMinAvgLength: number;
	fltNominalLength: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltParquetryFingerLength: number;
	fltParquetryFingerNominalLength: number;
	fltParquetryM2PerBox: number;
	fltProductWeight: number;
	fltToolHandleBagAmount: number;
	guOLDProductTypeId: string;
	intDefaultQuantityUnitType: number;
	intDiaUnitType: number;
	intFTStrengthGroupType: number;
	intHaulingRateRequired: number;
	intLengthDisplay: number;
	intM3Formula: number;
	intMeasurementType: number;
	intPPStrengthGroupType: number;
	intPriceMeasurement: number;
	intPriceType: number;
	intProductTypeId: number;
	intSawLogType: number;
	intSectionType: number;
	intSystemType: number;
	rowguid: string;
	txtCode: string;
	txtCodeOld: string;
	txtName: string;
	txtName2: string;
	txtNameOld: string;
	txtProductCode: string;
	txtShortName: string;
}

export class ProductType
{
	blnActive: boolean;
	blnBlockParquetry: boolean;
	blnBoardPlant: boolean;
	blnBoardPlantPL: boolean;
	blnBoardPlantPopular: boolean;
	blnBPFloorProduct: boolean;
	blnCHHManufactured: boolean;
	blnCustomParquetry: boolean;
	blnCutByMill: boolean;
	blnDesap: boolean;
	blnDesapPL: boolean;
	blnForestry: boolean;
	blnJoinery: boolean;
	blnKilnDried: boolean;
	blnLaminated: boolean;
	blnLogYard: boolean;
	blnMaintenance: boolean;
	blnMill: boolean;
	blnMillPL: boolean;
	blnOrders: boolean;
	blnParquetry: boolean;
	blnPoleCosting: boolean;
	blnPoleFinalInspection: boolean;
	blnPPToeDia: boolean;
	blnPrivateProperty: boolean;
	blnProductApplication: boolean;
	blnRequiresDurability: boolean;
	blnSawLog: boolean;
	blnStock: boolean;
	blnStockListAddDeck: boolean;
	blnStockListAddOverlay: boolean;
	blnStockListAddTG: boolean;
	blnToolHandle: boolean;
	blnTreatmentPL: boolean;
	blnTreatmentPlant: boolean;
	blnTrimming: boolean;
	blnUsuallySetLength: boolean;
	blnWeightCalculator: boolean;
	blnXArm: boolean;
	dteLastUpdated: Date;
	fltMaxAvgLength: number;
	fltMinAvgLength: number;
	fltNominalLength: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltParquetryFingerLength: number;
	fltParquetryFingerNominalLength: number;
	fltParquetryM2PerBox: number;
	fltProductWeight: number;
	fltToolHandleBagAmount: number;
	guOLDProductTypeId: string;
	intDefaultQuantityUnitType: number;
	intDiaUnitType: number;
	intFTStrengthGroupType: number;
	intHaulingRateRequired: number;
	intLengthDisplay: number;
	intM3Formula: number;
	intMeasurementType: number;
	intPPStrengthGroupType: number;
	intPriceMeasurement: number;
	intPriceType: number;
	intProductTypeId: number;
	intSawLogType: number;
	intSectionType: number;
	intSystemType: number;
	rowguid: string;
	txtCode: string;
	txtCodeOld: string;
	txtName: string;
	txtName2: string;
	txtNameOld: string;
	txtProductCode: string;
	txtShortName: string;
}

export namespace ProductType
{
	export enum enMeasurementType
	{
		WidthThicknessLength,
		LengthAndDiameter,
		LengthKN,
		Weight,
		NoDimension
	}
}

